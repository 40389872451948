import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Popup from './../../components/popup/popup';
import Loader from './../../components/loader/loader';
// import PageHeader from './../../components/page-header/page-header';
import Input from './../../components/input/input';
import Toggler from './../../components/toggler/toggler';
import Radio from './../../components/radio/radio';
import Footer from './../../components/footer/footer';

// Import styles
import './settings.scss';

interface props {
	'pageId': string
}

declare const window: any;

const Settings = (props: props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	let stripeTestKey: any = '';
	let stripeLiveKey: any = '';

	if (process.env?.REACT_APP_NAME === 'FindUpp') {
		stripeTestKey = process.env?.REACT_APP_FINDUPP_STRIPE_PUBLIC_TEST;
		stripeLiveKey = process.env?.REACT_APP_FINDUPP_STRIPE_PUBLIC_LIVE;
	}

	// if (process.env?.REACT_APP_NAME === 'TekBruv') {
	// 	stripeTestKey = process.env?.REACT_APP_TEKBRUV_STRIPE_PUBLIC_TEST;
	// 	stripeLiveKey = process.env?.REACT_APP_TEKBRUV_STRIPE_PUBLIC_LIVE;
	// }

	const stripeKey: any = (process.env?.REACT_APP_ENV === 'production' ? stripeLiveKey : stripeTestKey);
	const initAccountDetails: {[key: string]: any} = {
		'firstName': auth.firstName || '',
		'lastName': auth.lastName || '',
	}
	const initCheckout: {[key: string]: any} = {
		'credits': 50,
		'itemId': '4952387',
		'itemName': 'creditsXS', 
		'itemPrice': (20 * generalText.keys?.creditsPriceMultiplier),
		'itemDiscount': ((20 * generalText.keys?.creditsPriceMultiplier) * 0.1),
		'totalPrice': (20 * generalText.keys?.creditsPriceMultiplier) - ((20 * generalText.keys?.creditsPriceMultiplier) * 0.1),
		'name': '',
		'line1': '',
		'city': '',
		'postalCode': '',
		'state': ''
	}
	const initPassword: {[key: string]: any} = {
		'oldPassword': '',
		'newPassword': ''
	}
	const initEmailPreferences: {[key: string]: any} = {
		'isEmailPromotionsAllowed': auth.isEmailPromotionsAllowed ? auth.isEmailPromotionsAllowed : false,
		'isEmailAppUpdatesAllowed': auth.isEmailAppUpdatesAllowed ? auth.isEmailAppUpdatesAllowed : false,
		'isEmailMessageNotificationsAllowed': auth.isEmailMessageNotificationsAllowed ? auth.isEmailMessageNotificationsAllowed : false,
	}
	const initPopups: {[key: string]: any} = {
		'showGuidePopup': false,
		'showUnlockContentPopup': false,
		'showAccountDetailsPopup': false,
		'showPurchaseCreditsPopup': false,
		'showDeactivationPopup': false,
		'showChangePasswordPopup': false,
		'showSignOutOfAllDevicesPopup': false,
		'showSignOutPopup': false,
		'showEmailPreferencesPopup': false,
		'showReferralsPopup': false,
		'showCreditsPopup': false
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [stripePromise] = useState(() => loadStripe(stripeKey))
	const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
	const [accountDetails, setAccountDetails] = useState<{[key: string]: any}>(initAccountDetails);
	const [checkout, setCheckout] = useState<{[key: string]: any}>(initCheckout);
	const [password, setPassword] = useState<{[key: string]: any}>(initPassword);
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [emailPreferences, setEmailPreferences] = useState<{[key: string]: any}>(initEmailPreferences);
	const [accountDeactivationCounter, setAccountDeactivationCounter] = useState<number>(5);
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const textareaRef: any = useRef<any>(null);

	useEffect(() => {
		if (!_.isEmpty(errors)) {
			setErrors({});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkout, accountDetails]);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		
		if (queryParams.has('activePopup')) {
			const activePopup: any = queryParams.get('activePopup');

			setPopups({...initPopups, [activePopup]: true});

			window.history.replaceState(null, '', location.pathname);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Show referral popup by timout
	// useEffect(() => {
	// 	if (!auth.showReferralsPopup) { return; }

	// 	setTimeout(() => {
	// 		setPopups(prevState => {
	// 				if (JSON.stringify(prevState) !== JSON.stringify(initPopups)) { return prevState; }

	// 				return {...initPopups, 'showReferralsPopup': true}
	// 			}
	// 		)
	// 	}, 5000)
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(() => {
		// Facebook event
		if (window.fbq) {
			window.fbq('track', 'InitiateCheckout', {
				'event_time': Math.floor(Date.now() / 1000),
				'event_name': 'InitiateCheckout',
				'event_source_url': window.location.href,
				'action_source': 'website'
			});
		}
	}, [popups.showPurchaseCreditsPopup])

	const onShowGuide = async () => {
		api.put('/toggle-guide').then((res) => {
			setPopups(initPopups);
			setNotifications(res.data?.messages);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
			setPopups(initPopups);

		});
	}

	const onUnlockContent = () => {
		if (auth.credits < generalText.keys?.unlockContentPrice) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.notEnoughCreditsError);
			return false;
		}

		setIsLoading(true);

		api.put('/unlock-content').then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications({
				'title': generalText.messages?.unlockedPostsMessage?.title,
				'text': generalText.messages?.unlockedPostsMessage?.text
			});

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onSignOut = (isSignOutFromAll: boolean) => {
		setIsLoading(true);

		let endPoint: string;

		if (!isSignOutFromAll) {
			endPoint = '/sign-out';
		} else {
			endPoint = '/sign-out-of-all-devices';
		}

		api.post(endPoint).then((res) => {			
			dispatch(setCurrentUser({'toggleUpdate': !auth.toggleUpdate}));

			setTimeout(() => {
				navigate(slugByPageId('sign-in', lang, routes), {'replace': true});
			}, 200);
		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
		});
	}

	const onCopyLink = () => {
		textareaRef.current.select();
		document.execCommand('copy');
		setIsLinkCopied(true);

		// Add gta referral_link_copied
	}

	const onChangeTheme = () => {
		setIsLoading(true);

		let stateClone: any = {
			'isDarkThemeEnabled': !auth.isDarkThemeEnabled
		}

		api.post('/edit-user-details', stateClone).then((res) => {
			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));
			setIsLoading(false);

		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		});
	}


	const onEditUserDetails = async () => {
		setIsLoading(true);
		setErrors({});

		// Validate fields
		let stateClone: any = _.cloneDeep(accountDetails);

		// Validation
		let fieldErrors = validateFields(stateClone, ['user']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		api.post('/edit-user-details', stateClone).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(res.data?.messages);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setAccountDetails(initAccountDetails);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onAccountDeactivation = () => {
		setIsLoading(true);

		api.put('/deactivate-user').then((res) => {
			setIsLoading(false);
			setPopups(initPopups);

			dispatch(setCurrentUser({'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data);
		});
	}

	const onEditEmailPreferences = () => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(emailPreferences);

		api.post('/edit-email-preferences', stateClone).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(res.data?.messages);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onChangePassword = () => {
		setIsLoading(true);

		// Validate fields
		let stateClone: any = _.cloneDeep(password);

		// Validation
		let fieldErrors = validateFields(stateClone, ['password']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		api.post('/change-password', stateClone).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setPassword(initPassword);
			setNotifications(res.data?.messages);

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setPassword(initPassword);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onHandlePayment = async (e: any, stripe: any, elements: any) => {
		// Block native form submission.
		e.preventDefault();

		setIsLoading(true);

		// const {stripe, elements} = props;
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		let stateClone: any = _.cloneDeep(checkout);

		// Validation
		let fieldErrors = validateFields(stateClone, ['checkout']);

		let paymentMethod: any = await new Promise((resolve, reject) => {
			stripe.createPaymentMethod({
				'type': 'card',
				'card': elements.getElement(CardElement),
				'billing_details': {
					'address': {
						'line1': checkout.line1 || null,
						'city': checkout.city || null,
						'postal_code': checkout.postalCode || null,
						'state': checkout.state || null,
					},
					'name': checkout.name || null,
					'email': auth.email || null
				},
			}).then((res: {[key: string]: any}) => {
				if (!_.isEmpty(fieldErrors) || res.error) {
					let errors = {};

					if (!_.isEmpty(fieldErrors)) {
						errors = {...errors, ...fieldErrors}
					}

					if (res.error && res.error.message) {
						errors = {...errors, 'card': res.error.message}
					}

					setIsLoading(false);
					setErrors(prevState => ({...prevState, ...errors}));

					return;
				}

				resolve(res.paymentMethod);
			});
		});

		const paymentResponse: any = await new Promise((resolve, reject) => {
			api.post('/handle-purchase', {'id': paymentMethod.id, 'credits': checkout.credits}).then(async (res) => {
				const clientSecret = res.data.client_secret;

				const { err, paymentIntent } = await stripe.confirmCardPayment(clientSecret);

				if (err) {
					resolve(err);
				} else {
					resolve(paymentIntent);
				}
			}).catch((err) => {
				resolve(err);
			});
		});

		let creditsAfterPurchaseData: any = {
			'paymentResponse': (paymentResponse.id ? paymentResponse : paymentResponse.response.data),
			'credits': checkout.credits,
			'discountedPrice': checkout.itemPrice
		}

		api.post('/set-credits-after-purchase', creditsAfterPurchaseData).then(async (res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setCheckout(initCheckout);
			setNotifications(generalText.messages?.creditsSuccessfullyPurchased);

			// Facebook event
			if (window.fbq) {
				window.fbq('track', 'Purchase', {
					'currency': 'EUR', 
					'value': checkout.itemPrice
				});
			}

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));
		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setCheckout(initCheckout);
			setNotifications(err.response?.data?.messages);
		});
	};

	if (_.isEmpty(text)) { return null; }

	let price: number = checkout.credits * generalText.keys?.creditsPriceMultiplier;
	let discountedPrice: number = price;

	if (checkout.credits >= 50) {
		discountedPrice = price - (price * 0.2);
	}

	if (checkout.credits >= 100) {
		discountedPrice = price - (price * 0.3);
	}

	let priceXS = 10 * generalText.keys?.creditsPriceMultiplier;
	let priceSM = (50 * generalText.keys?.creditsPriceMultiplier);
	let priceMD = (100 * generalText.keys?.creditsPriceMultiplier)

	let discountedPriceXS = priceXS;
	let discountedPriceSM = priceSM - (priceSM * 0.2);
	let discountedPriceMD = priceMD - (priceMD * 0.3);

	let discountXS = priceXS * 0;
	let discountSM = priceSM * 0.2;
	let discountMD = priceMD * 0.3;

	return (
		<div className="SETTINGS">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle.replace('%repStr1%', auth.firstName) }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				submitBtn={text.guidePopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showGuidePopup}
				onSubmit={() => onShowGuide()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={text.unlockContentPopup?.title}
				submitBtn={text.unlockContentPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showUnlockContentPopup}
				onSubmit={() => onUnlockContent()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.unlockContentPopup?.text1 &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html: text.unlockContentPopup?.text1 }}></div>
							}

							{
								text.unlockContentPopup?.text2 &&
								<div className="col-12">
									{
										parse(text.unlockContentPopup?.text2, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.unlockContentPrice }</span>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.accountDetailsPopup?.title}
				submitBtn={text.accountDetailsPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showAccountDetailsPopup}
				onSubmit={() => onEditUserDetails()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="FIELD">
									<Input
										value={accountDetails.firstName}
										label={text.accountDetailsPopup?.input1?.label}
										placeholder={text.accountDetailsPopup?.input1?.placeholder}
										type="text"
										errors={errors.firstName}
										onChange={(val: string) => setAccountDetails(prevState => ({...prevState, 'firstName': val}))}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="FIELD">
									<Input
										value={accountDetails.lastName}
										label={text.accountDetailsPopup?.input2?.label}
										placeholder={text.accountDetailsPopup?.input2?.label}
										type="text"
										errors={errors.lastName}
										onChange={(val: string) => setAccountDetails(prevState => ({...prevState, 'lastName': val}))}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="FIELD">
									<Input
										value={auth.email}
										label={text.accountDetailsPopup?.input3?.label}
										type="text"
										placeholder={text.accountDetailsPopup?.input3?.label}
										disabled={true}
										errors={errors.email}
										onChange={(val: string) => {}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Elements
				stripe={stripePromise}
			>
				<ElementsConsumer>
					{({elements, stripe}) => (
						<Popup
							title={text.purchaseCreditsPopup?.title}
							image={text.purchaseCreditsPopup?.image1}
							submitBtn={text.purchaseCreditsPopup?.button1?.text}
							closeBtn={generalText?.generalPopup?.button1?.text}
							active={popups.showPurchaseCreditsPopup}
							onSubmit={(e: any) => onHandlePayment(e, stripe, elements)}
							onClose={() => setPopups(initPopups)}
							disabledSubmitBtn={!stripe}
						>
							<div className="SECTION no-border">
								<div className="grid">
									<div className="row">
										<div className="col-4">
											<div className="FIELD">
												<Radio
													value={100}
													name="creditsAmount"
													selectedValue={checkout.credits}
													label={text.purchaseCreditsPopup?.radio3?.placeholder.replace('%repStr1%', (discountedPriceMD / generalText.keys?.creditsPriceMultiplier)).replace('%repStr2%', discountedPriceMD)}
													errors={errors.credits}
													onChange={(val: number) => setCheckout(prevState => ({
														...prevState, 
														'credits': val,
														'itemId': '8653345', 
														'itemName': 'credits100', 
														'itemPrice': priceMD,
														'itemDiscount': discountMD,
														'totalPrice': discountedPriceMD
													}))}
												/>
											</div>
										</div>

										<div className="col-4">
											<div className="FIELD">
												<Radio
													value={50}
													name="creditsAmount"
													selectedValue={checkout.credits}
													label={text.purchaseCreditsPopup?.radio2?.placeholder.replace('%repStr1%', (discountedPriceSM / generalText.keys?.creditsPriceMultiplier)).replace('%repStr2%', discountedPriceSM)}
													errors={errors.credits}
													onChange={(val: number) => setCheckout(prevState => ({
														...prevState, 
														'credits': val,
														'itemId': '6532341', 
														'itemName': 'credits50', 
														'itemPrice': priceSM,
														'itemDiscount': discountSM,
														'totalPrice': discountedPriceSM
													}))}
												/>
											</div>
										</div>

										<div className="col-4">
											<div className="FIELD">
												<Radio
													value={10}
													name="creditsAmount"
													selectedValue={checkout.credits}
													label={text.purchaseCreditsPopup?.radio1?.placeholder.replace('%repStr1%', (discountedPriceXS / generalText.keys?.creditsPriceMultiplier)).replace('%repStr2%', discountedPriceXS)}
													errors={errors.credits}
													onChange={(val: number) => setCheckout(prevState => ({
														...prevState, 
														'credits': val,
														'itemId': '4952387', 
														'itemName': 'credits10', 
														'itemPrice': priceXS,
														'itemDiscount': discountXS,
														'totalPrice': discountedPriceXS
													}))}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="SECTION">
								<div className="grid">
									<div className="row">
										{
											text.purchaseCreditsPopup?.heading1 &&
											<div className="col-12">
												<div className="FIELD">
													<div className="FIELD__heading">
														{ text.purchaseCreditsPopup?.heading1 }
													</div>
												</div>
											</div>
										}

										<div className="col-12">
											<div className="FIELD">

												<Input
													value={checkout.name}
													label={text.purchaseCreditsPopup?.input1?.label}
													type="text"
													placeholder="Enter name on card..."
													errors={errors.name}
													onChange={(val: string) => setCheckout(prevState => ({...prevState, 'name': val}))}
												/>
											</div>
										</div>

										<div className="col-12">
											<div className="FIELD">
												<div className={'CARDELEMENT' + (errors.card ? ' error' : '')}>
													{
														text.purchaseCreditsPopup?.input2?.label &&
														<label>
															{ text.purchaseCreditsPopup?.input2?.label }
														</label>
													}

													<CardElement
														className="CARDINPUT"
														onChange={(e) => {
															if (e.error) {
																setErrors(prevState => ({...prevState, 'card': e.error?.message}))
															} else {
																setErrors({})
															}
														}}
														options={{
															style: {
																base: {
																	fontSize: '15px',
																	fontFamily: 'Outfit',
																	lineHeight: '20px',
																	fontWeight: '400',
																	iconColor: (!auth.isDarkThemeEnabled ? '#333333' : '#dddddd'),
																	color: (!auth.isDarkThemeEnabled ? '#333333' : '#dddddd'),
																	'::placeholder': {
																		color: (!auth.isDarkThemeEnabled ? '#dddddd' : '#333333'),
																		fontSize: '15px',
																		fontWeight: '400'
																	},
																},
																invalid: {
																	color: '#cd1717',
																	iconColor: '#cd1717',
																},
															}
														}}
													/>

													{
														errors.card &&
														<p className="error">
															{ errors.card }
														</p>
													}
												</div>
											</div>
										</div>
									</div>

									{
										text.purchaseCreditsPopup?.badges1?.length > 0 &&
										<div className="row">
											{
												_.map(text.purchaseCreditsPopup?.badges1, (val1: any, i1: number) => {
													return (
														<div key={i1} className={(val1.text ? 'col-6' : 'col-3')}>
															<div className="SECTION__item">
																{
																	val1.icon &&
																	<span className={'inline-block ' + (val1.text ? ' font-md' : 'font-xl') + ' ' + val1.icon}></span>
																}

																<span className="inline-block font-xxxs">&nbsp;&nbsp;{ val1.text }</span>
															</div>
														</div>
													)
												})
											}
										</div>
									}
								</div>
							</div>

							<div className="SECTION">
								<div className="grid">
									<div className="row">
										{
											text.purchaseCreditsPopup?.heading2 &&
											<div className="col-12">
												<div className="FIELD">
													<div className="FIELD__heading">
														{ text.purchaseCreditsPopup?.heading2 }
													</div>
												</div>
											</div>
										}

										<div className="col-12">
											<div className="FIELD">
												<Input
													value={checkout.line1}
													label={text.purchaseCreditsPopup?.input3?.label}
													placeholder={text.purchaseCreditsPopup?.input3?.placeholder}
													type="text"
													errors={errors.line1}
													onChange={(val: string) => setCheckout(prevState => ({...prevState, 'line1': val}))}
												/>
											</div>
										</div>

										<div className="col-12 col-sm-6">
											<div className="FIELD">
												<Input
													value={checkout.city}
													label={text.purchaseCreditsPopup?.input4?.label}
													placeholder={text.purchaseCreditsPopup?.input4?.placeholder}
													type="text"
													errors={errors.city}
													onChange={(val: string) => setCheckout(prevState => ({...prevState, 'city': val}))}
												/>
											</div>
										</div>

										<div className="col-12 col-sm-6">
											<div className="FIELD">
												<Input
													value={checkout.postalCode}
													label={text.purchaseCreditsPopup?.input5?.label}
													placeholder={text.purchaseCreditsPopup?.input5?.placeholder}
													type="text"
													errors={errors.postalCode}
													onChange={(val: string) => setCheckout(prevState => ({...prevState, 'postalCode': val}))}
												/>
											</div>
										</div>

										<div className="col-12">
											<div className="FIELD">
												<Input
													value={checkout.state}
													label={text.purchaseCreditsPopup?.input6?.label}
													placeholder={text.purchaseCreditsPopup?.input6?.placeholder}
													type="text"
													errors={errors.state}
													onChange={(val: string) => setCheckout(prevState => ({...prevState, 'state': val}))}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="SECTION">
								<div className="grid">
									<div className="row">
										<div className="col-6">
											{
												text.purchaseCreditsPopup?.heading3 &&
												<div className="FIELD">
													<div className="FIELD__heading">
														{
															parse(text.purchaseCreditsPopup?.heading3, {
																replace: (domNode: any) => {
																	if (domNode.attribs?.class === '%repStr1%') {
																		return <>{ checkout.credits }</>;
																	}
																}
															})
														}
													</div>
												</div>
											}
										</div>

										<div className="col-6">
											{
												text.purchaseCreditsPopup?.heading4 &&
												<div className="FIELD">
													<div className="FIELD__heading">
														{
															parse(text.purchaseCreditsPopup?.heading4, {
																replace: (domNode: any) => {
																	if (domNode.attribs?.class === '%repStr1%') {
																		return <>{ discountedPrice }</>;
																	}
																}
															})
														}
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</Popup>
					)}
				</ElementsConsumer>
			</Elements>

			<Popup
				title={generalText.referralPopup?.title}
				submitBtn={auth.mainProfile?.slug ? (
					!isLinkCopied ? 
					generalText.referralPopup?.button1?.text1 : 
					generalText.referralPopup?.button1?.text2
				) : ''}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showReferralsPopup}
				onSubmit={() => onCopyLink()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								{
									auth.mainProfile?.slug && generalText.referralPopup?.text1 &&
									<div dangerouslySetInnerHTML={{ __html: generalText.referralPopup?.text1 }}></div>
								}

								{
									!auth.mainProfile?.slug && generalText.referralPopup?.text2 &&
									<div className="SETTINGS__coupon">
										<div dangerouslySetInnerHTML={{ __html: generalText.referralPopup?.text2 }}></div>
									</div>
								}

								{
									auth.showReferralsPopup && auth.mainProfile?.slug && generalText.referralPopup?.text3 &&
									<div dangerouslySetInnerHTML={{ __html: generalText.referralPopup?.text3 }}></div>
								}

								{
									auth.mainProfile?.slug &&
									<div className="SETTINGS__coupon">
										<div className="FIELD">
											<div className="INPUT">
												{
													generalText.referralPopup?.input1?.label &&
													<label>
														{ generalText.referralPopup?.input1?.label }
													</label>
												}

												<input
													ref={textareaRef}
													value={auth?.invitationCodeShareable}
													type="text"
													onChange={() => {}}
												/>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.creditsPopup?.title}
				image={text.creditsPopup?.image1}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showCreditsPopup}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.creditsPopup?.text1 &&
								<div className="col-12">
									{
										parse(text.creditsPopup?.text1, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <>{ discountedPriceXS }</>;
												}
												if (domNode.attribs?.class === '%repStr2%') {
													return <>{ discountedPriceXS / generalText.keys?.creditsPriceMultiplier }</>;
												}
												if (domNode.attribs?.class === '%repStr3%') {
													return <>{ discountedPriceSM }</>;
												}
												if (domNode.attribs?.class === '%repStr4%') {
													return <>{ discountedPriceSM / generalText.keys?.creditsPriceMultiplier }</>;
												}
												if (domNode.attribs?.class === '%repStr5%') {
													return <>{ discountedPriceMD }</>;
												}
												if (domNode.attribs?.class === '%repStr6%') {
													return <>{ discountedPriceMD / generalText.keys?.creditsPriceMultiplier }</>;
												}
												if (domNode.attribs?.class === '%repStr7%') {
													return <span>{ generalText.keys?.raiseProfilePrice }</span>;
												}
												if (domNode.attribs?.class === '%repStr8%') {
													return <span>{ generalText.keys?.newListingsPostPrice }</span>;
												}
												if (domNode.attribs?.class === '%repStr9%') {
													return <span>{ generalText.keys?.newProfilePrice }</span>;
												}
												if (domNode.attribs?.class === '%repStr10%') {
													return <span>{ generalText.keys?.sendMessagePrice }</span>;
												}
												if (domNode.attribs?.class === '%repStr11%') {
													return <span>{ generalText.keys?.hoursToAllowProfileRaise }</span>;
												}
												if (domNode.attribs?.class === '%repStr12%') {
													return <span>{ generalText.keys?.dailyCreditsAmount }</span>;
												}
												if (domNode.attribs?.class === '%repStr13%') {
													return <span>{ generalText.keys?.newEducationalPostEarning }</span>;
												}
												if (domNode.attribs?.class === '%repStr14%') {
													return <span>{ generalText.keys?.referralEarning }</span>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.emailPreferencesPopup?.title}
				submitBtn={text.emailPreferencesPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showEmailPreferencesPopup}
				onSubmit={() => onEditEmailPreferences()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.emailPreferencesPopup?.toggler1?.on &&
								text.emailPreferencesPopup?.toggler1?.off &&
								<div className="col-12">
									<div className="FIELD">
										<Toggler
											value={emailPreferences.isEmailPromotionsAllowed}
											checkedText={text.emailPreferencesPopup?.toggler1?.on}
											uncheckedText={text.emailPreferencesPopup?.toggler1?.off}
											onChange={(val: boolean) => setEmailPreferences(prevState => ({...prevState, 'isEmailPromotionsAllowed': val}))}
										/>
									</div>
								</div>
							}

							{
								text.emailPreferencesPopup?.toggler2?.on &&
								text.emailPreferencesPopup?.toggler2?.off &&
								<div className="col-12">
									<div className="FIELD">
										<Toggler
											value={emailPreferences.isEmailAppUpdatesAllowed}
											checkedText={text.emailPreferencesPopup?.toggler2?.on}
											uncheckedText={text.emailPreferencesPopup?.toggler2?.off}
											onChange={(val: boolean) => setEmailPreferences(prevState => ({...prevState, 'isEmailAppUpdatesAllowed': val}))}
										/>
									</div>
								</div>
							}

							{
								text.emailPreferencesPopup?.toggler3?.on &&
								text.emailPreferencesPopup?.toggler3?.off &&
								<div className="col-12">
									<div className="FIELD">
										<Toggler
											value={emailPreferences.isEmailMessageNotificationsAllowed}
											checkedText={text.emailPreferencesPopup?.toggler3?.on}
											uncheckedText={text.emailPreferencesPopup?.toggler3?.off}
											onChange={(val: boolean) => setEmailPreferences(prevState => ({...prevState, 'isEmailMessageNotificationsAllowed': val}))}
										/>

										{
											!emailPreferences.isEmailMessageNotificationsAllowed &&
											<p>Please note that by turning off message notifications you will not receive any email notifications when someone will contact you.</p>
										}
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.changePasswordPopup?.title}
				submitBtn={text.changePasswordPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showChangePasswordPopup}
				onSubmit={() => onChangePassword()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="FIELD">
									<Input
										value={password.oldPassword}
										label={text.changePasswordPopup?.input1?.label}
										placeholder={text.changePasswordPopup?.input1?.placeholder}
										type="password"
										errors={errors.oldPassword}
										onChange={(val: string) => setPassword(prevState => ({...prevState, 'oldPassword': val}))}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="FIELD">
									<Input
										value={password.newPassword}
										label={text.changePasswordPopup?.input2?.label}
										placeholder={text.changePasswordPopup?.input2?.placeholder}
										type="password"
										errors={errors.newPassword}
										onChange={(val: string) => setPassword(prevState => ({...prevState, 'newPassword': val}))}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.deactivateAccountPopup?.title}
				submitBtn={text.deactivateAccountPopup?.button1?.text + ' (' + accountDeactivationCounter + ')'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showDeactivationPopup}
				onSubmit={() => {
					if (accountDeactivationCounter <= 0) {
						onAccountDeactivation();
					} else {
						setAccountDeactivationCounter(prevState => (prevState - 1));
					}
				}}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.deactivateAccountPopup?.text1 &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html: text.deactivateAccountPopup?.text1 }}></div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.signOutOfAllDevicesPopup?.title}
				text={text.signOutOfAllDevicesPopup?.text1}
				submitBtn={text.signOutOfAllDevicesPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showSignOutOfAllDevicesPopup}
				onSubmit={() => onSignOut(true)}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={text.signOutPopup?.title}
				text={text.signOutPopup?.text1}
				submitBtn={text.signOutPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showSignOutPopup}
				onSubmit={() => onSignOut(false)}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #346324623523']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			{/*<PageHeader heading={text.pageTitle.replace('%repStr1%', auth.firstName)} />*/}
			
			<div className="SECTION">
				<div className="grid">
					<div className="row">
						{
							_.map(text.links, (val1: any, i1: number) => {
								return (
									<div key={i1} className={'SECTION__item ' + val1.gridClasses}>
										<div className="SECTION__inner bg-diff1">
											<div className="grid">
												<div className="row">
													<div className="col-12">
														{
															val1.heading &&
															<div className="SECTION__title font-md">{ val1.heading }</div>
														}

														{/*{
															i1 === 0 && auth?.adminType >= 1 &&
															<div className="SETTINGS__item">
																<Link to="/cms/" className="AHREF">
																	<span className="icon-chart-network-regular"></span>
																	CMS
																</Link>
															</div>
														}*/}

														{
															_.map(val1.items, (val2: any, i2: number) => {
																let unlockedPostsTimeLeft: any = moment.duration(moment(auth.unlockedContentEndsAt).diff(moment()));

																return (
																	<div key={i2} className="SETTINGS__item">
																		{
																			val2.popup &&
																			<span onClick={() => {
																				setPopups({...initPopups, [val2.popup]: true});
																			}} className="AHREF">
																				<span className={val2.icon}></span>
																				{ 
																					(
																						!val2.text1 ||
																						!val2.text2
																					) &&
																					val2.text
																				}

																				{ 
																					val2.text1 &&
																					val2.text2 &&
																					<>{ auth.unlockedContentEndsAt ? val2.text2 : val2.text1 }</>
																				} 
																				
																				{
																					(val2.popup === 'showUnlockContentPopup') &&
																					auth.unlockedContentEndsAt &&
																					<>
																						<> - </>
																						<span className="font-xxxxs clr-green">
																							{
																								' (' + unlockedPostsTimeLeft.days() + 'd ' +
																								unlockedPostsTimeLeft.hours() + 'h ' +
																								unlockedPostsTimeLeft.minutes() + 'm)'
																							}
																						</span>
																					</>
																				}
																			</span>
																		}

																		{
																			val2.link &&
																			<Link to={slugByPageId(val2.link, lang, routes)} className="AHREF">
																				<span className={val2.icon}></span>
																				{ val2.text }
																			</Link>
																		}

																		{
																			val2.url &&
																			<a href={val2.url} className="AHREF" target="_blank" rel="noopener noreferrer">
																				<span className={val2.icon}></span>
																				{ val2.text }
																			</a>
																		}

																		{
																			val2.icon1 &&
																			val2.icon2 &&
																			<span className="AHREF" onClick={() => onChangeTheme()}>
																				{
																					!auth.isDarkThemeEnabled &&
																					<>
																						<span className={val2.icon1}></span>
																						{ val2.text1 }
																					</>
																				}

																				{
																					auth.isDarkThemeEnabled &&
																					<>
																						<span className={val2.icon2}></span>
																						{ val2.text2 }
																					</>
																				}
																			</span>
																		}
																	</div>
																)
															})
														}
														
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default Settings;