import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import parse, { domToReact } from 'html-react-parser';
import { IPInfoContext } from 'ip-info-react';

// Import helpers
// import {
// 	initGoogle,
// 	initMeta,
// 	initHotjar
// } from './../../other/helpers/init-third-parties';
import { getCookieValue } from './../../other/helpers/cookies';
import { slugByPageId } from './../../other/helpers/route-finder';
import { setCookie } from './../../other/helpers/cookies';

// Import styles
import './consent.scss';

interface props {};

const Consent = (props: props) => {
	const location = useLocation();

	const userInfo = useContext(IPInfoContext);

	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const [isCookiesVisible, setIsConsentVisible] = useState<boolean>(false);
	const [siteCookiesAccepted, setCookiesAccepted] = useState<boolean>(false);

	useEffect(() => {
		setCookiesAccepted(getCookieValue('siteCookiesAccepted') ? true : false);
		
		if (getCookieValue('siteCookiesAccepted') || getCookieValue('siteCookiesDeclined')) {
			return;
		}

		if (userInfo.ip !== undefined) {
			if (userInfo.continent_code !== 'EU') {
				onCookiesAccept();
				return;
			}

			setTimeout(() => {
				setIsConsentVisible(true);
			}, 1000);
		}
	}, [userInfo]);


	const onCookiesAccept = () => {
		setCookie('siteCookiesAccepted', 'true', 365);

		setCookiesAccepted(true);

		if (process.env?.REACT_APP_ENV !== 'production') {
			return
		}
		
		// // Add scripts
		// initGoogle();
		// initMeta();
		// initHotjar();
	}

	if (
		siteCookiesAccepted || 
		!generalText.consent?.text1 ||
		// location.pathname === slugByPageId('decline-cookies', lang, routes) ||
		location.pathname !== slugByPageId('main', lang, routes)
	) { return null; }

	return (
		<div className={'SECTION padding-wrap-sm CONSENT text-center' + (isCookiesVisible ? ' active' : '')}>
			<div className="grid">
				<div className="row">
					<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-0">
						<div className="SECTION__inner bg-diff1 padding-wrap-sm">
							<div className="row">
								<div className="col-12 text-center">
									{
										parse(generalText.consent?.text1, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <Link to={slugByPageId('cookies', lang, routes)} className="LINK">{domToReact(domNode.children)}</Link>;
												}
											}
										})
									}
								</div>

								<div className="col-4 push-2 col-md-4 push-md-2">
									{
										generalText.consent?.button1?.text &&
										<span onClick={() => onCookiesAccept()} className={'BUTTON diff2 size1 ' + generalText.consent?.button1?.diff}>
											{ generalText.consent?.button1?.text }
										</span>
									}
								</div>

								<div className="col-4 col-md-4">
									{
										generalText.consent?.button2?.text &&
										<Link to={slugByPageId(generalText.consent?.button2?.link, lang, routes)} className={'BUTTON size1 ' + generalText.consent?.button2?.diff}>
											{ generalText.consent?.button2?.text }
										</Link>
									}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default Consent;