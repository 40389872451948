import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import parse, { domToReact } from 'html-react-parser';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';
import { validateFields } from './../../other/helpers/validate-fields';
import { checkCookie } from './../../other/helpers/cookies';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import Input from './../../components/input/input';
import Upload from './../../components/upload/upload';
import Textarea from './../../components/textarea/textarea';
import Select from './../../components/select/select';
import Toggler from './../../components/toggler/toggler';
import Popup from './../../components/popup/popup';
import Loader from './../../components/loader/loader';
import PageHeader from './../../components/page-header/page-header';

// Import styles
import './edit-profile.scss';

interface props {
	'pageId': string
}

const EditProfile = (props: props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id }: any = useParams();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initProfile: {[key: string]: any} = {
		'isVisible': true,
		'isMain': true,
		'isContactable': true,
		'covers': [],
		'images': [],
		'name': '',
		'slug': '',
		'country': '',
		'city': '',
		'interests': ['Find connections'],
		'types': [],
		'category': '',
		'skills': [],
		'about': '',
		'offer': '',
		'networks': [
			{
				'network': '',
				'url': ''
			}
		]
	}
	const initPopups: {[key: string]: any} = {
		'showExitConfirmationPopup': false,
		'showRefillFormPopup': false,
		'showProfileRemovePopup': false
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};
	
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [slug, setSlug] = useState<string>('');
	const [profile, setProfile] = useState<{[key: string]: any}>(initProfile);
	const [profileChangeCount, setProfileChangeCount] = useState<number>(1);
	const [isExtendedForm, setIsExtendedForm] = useState<boolean>(id);
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	useEffect(() => {
		api.get('/get-signed-user/').then((res) => {		
			if (res.data.availableProfiles <= res.data.profilesCount) {
				return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
			}
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!_.isEmpty(errors)) {
			setErrors({});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	useEffect(() => {
		// Save profile refill data to local storage
		setProfileChangeCount(profileChangeCount + 1);

		// Save every two profile array changes
		// if (id || profileChangeCount % 2 !== 0) { return; }

		// Save after 3 profile array changes
		if (id || profileChangeCount <= 3) { return; }

		let stateClone = _.cloneDeep(profile);

		delete stateClone.covers;
		delete stateClone.images;

		_.forEach(stateClone, (val1, i1) => {
			if (_.isEmpty(val1)) {
				delete stateClone[i1];
			}
		})

		localStorage.setItem('profileRefill', JSON.stringify(stateClone));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	useEffect(() => {
		if (!id) {
			// Check if there's a cookie with profile refill data
			if (localStorage.getItem('profileRefill')) {
				setPopups(prevState => ({...initPopups, 'showRefillFormPopup': true}))
			}

			return;
		}

		if (id && localStorage.getItem('profileRefill')) {
			localStorage.removeItem('profileRefill');
		}

		setIsLoading(true);

		api.get('/get-user-profile/' + id).then((res) => {
			setIsLoading(false);
			setSlug(res.data.slug);
			setProfile({
				'isVisible': res.data.isVisible,
				'isMain': res.data.isMain,
				'isContactable': res.data.isContactable,
				'covers': res.data.covers?.length > 0 ? [
					{		
						'path': res.data.covers[0].path,
						'preview': apiBasePath + '/uploads/' + (checkCookie('siteWebpSupported') ? res.data.covers[0].path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : res.data.covers[0].path),
						'name': res.data.covers[0].path,
						'rotation': res.data.covers[0].rotation || 0,
						'oldImage': true,
						'isRotationChanged': false
					}
				] : initProfile.covers,
				'images': res.data.images?.length > 0 ? [
					{
						'path': res.data.images[0].path,
						'preview': apiBasePath + '/uploads/' + (checkCookie('siteWebpSupported') ? res.data.images[0].path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : res.data.images[0].path),
						'name': res.data.images[0].path,
						'rotation': res.data.images[0].rotation || 0,
						'oldImage': true,
						'isRotationChanged': false
					}
				] : initProfile.images,
				'name': res.data.name || initProfile.name,
				'slug': res.data.slug || initProfile.slug,
				'country': res.data.country || initProfile.country,
				'city': res.data.city || initProfile.city,
				'interests': res.data.interests || initProfile.interests,
				'types': res.data.types || initProfile.types,
				'category': res.data.category || initProfile.category,
				'skills': res.data.skills || initProfile.skills,
				'about': res.data.about || initProfile.about,
				'offer': res.data.offer || initProfile.offer,
				'networks': res.data.networks.length > 0 ? res.data.networks : initProfile.networks
			});
			setIsExtendedForm(true);

		}).catch((err) => {
			navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFormRefill = () => {
		let profileRefill: any = localStorage.getItem('profileRefill');
		profileRefill = JSON.parse(profileRefill);

		setPopups(initPopups);
		setProfile({
			'isVisible': profileRefill.isVisible || initProfile.isVisible,
			'isMain': profileRefill.isMain || initProfile.isMain,
			'isContactable': profileRefill.isContactable || initProfile.isContactable,
			'covers': [],
			'images': [],
			'name': profileRefill.name || initProfile.name,
			'slug': profileRefill.slug || initProfile.slug,
			'country': profileRefill.country || initProfile.country,
			'city': profileRefill.city || initProfile.city,
			'interests': profileRefill.interests || initProfile.interests,
			'types': profileRefill.types || initProfile.types,
			'category': profileRefill.category || initProfile.category,
			'skills': profileRefill.skills || initProfile.skills,
			'about': profileRefill.about|| initProfile.about,
			'offer': profileRefill.offer || initProfile.offer,
			'networks': profileRefill.networks.length > 0 ? profileRefill.networks : initProfile.networks
		});
	}

	const onSubmitForm = async (e: any) => {
		e.preventDefault();

		setIsLoading(true);

		let stateClone: any = _.cloneDeep(profile);

		// Remove empty fields prior validation
		if (!stateClone.city) { delete stateClone.city; }
		if (!stateClone.skills || stateClone.skills.length === 0) { delete stateClone.skills; }
		if (!stateClone.offer) { delete stateClone.offer; }
		if (_.isEmpty(stateClone.covers)) { delete stateClone.covers; }
		
		// Validation
		let fieldErrors = validateFields(stateClone, ['profile', 'filter', 'general']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);
			
			// window.scrollTo({'top': 0, 'behavior': 'smooth'});
			const scrollZone: any = document.getElementsByClassName('PAGE')[0];

			if (scrollZone) {
				scrollZone.scrollTo({'top': 0, 'behavior': 'smooth'});
			}

			return;
		}

		// Create FormData and append items
		let formData: any = new FormData();

		_.forEach(stateClone, (val1, i1) => {
			if (i1 === 'images' || i1 === 'covers') { return; }

			let value: string = '';

			if (typeof val1 === 'object' || Array.isArray(val1)) {

				value = JSON.stringify(val1);
				
				formData.append(i1, value);
				
				return;
			}

			formData.append(i1, val1);
		});

		// Check if covers array is not empty
		if (stateClone.covers?.length > 0) {
			let covers: any = stateClone['covers'][0];
			
			formData.append('coversData', JSON.stringify(covers));
			
			// Re-generate and re-upload image if the rotation has changed
			if (covers.oldImage && covers.isRotationChanged) {
				const url = apiBasePath + '/uploads/' + covers.path;
				
				await new Promise((resolve, reject) => {
					fetch(url, {'method': 'GET'}).then(async res => {
						const blob = await res.blob();
						const file: any = new File([blob], covers.path, {'type': blob.type});
						file['rotation'] = covers.rotation;

						covers = file;

						resolve(true);
					});
				});
			}

			if (covers.oldImage && !covers.isRotationChanged) {
				covers = JSON.stringify(covers);
			}

			formData.append('covers', covers);
		}

		// Check if image array is not empty
		if (stateClone.images.length > 0) {
			let images: any = stateClone['images'][0];
			
			formData.append('imagesData', JSON.stringify(images));
			
			// Re-generate and re-upload image if the rotation has changed
			if (images.oldImage && images.isRotationChanged) {
				const url = apiBasePath + '/uploads/' + images.path;
				
				await new Promise((resolve, reject) => {
					fetch(url, {'method': 'GET'}).then(async res => {
						const blob = await res.blob();
						const file: any = new File([blob], images.path, {'type': blob.type});
						file['rotation'] = images.rotation;

						images = file;

						resolve(true);
					});
				});
			}

			if (images.oldImage && !images.isRotationChanged) {
				images = JSON.stringify(images);
			}

			formData.append('images', images);
		}

		// If this is profile update, pass profile id to formData
		if (id) {
			formData.append('profileId', id)
		}

		api.post('/edit-user-profile', formData).then((res) => {

			if (localStorage.getItem('profileRefill')) {
				localStorage.removeItem('profileRefill');
			}

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

			if (auth.profilesCount === 1 && auth.showGuide) {
				navigate(slugByPageId('main', lang, routes));
			} else {
				navigate(slugByPageId('user-profiles', lang, routes));
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onRemoveProfile = () => {
		// If there's no profile id provided
		if (!id) { return; }

		setIsLoading(true);

		let profileData: any = {
			'slug': slug
		}

		api.put('/remove-profile', profileData).then((res) => {
			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

			navigate(slugByPageId('user-profiles', lang, routes));

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	const recognizeNetwork = (val1: any, i1: number) => {
		const patterns: { [key: string]: RegExp[] } = {
			'Facebook': [/^facebook\.com$/, /^fb\.com$/, /^m\.facebook\.com$/, /^mobile\.facebook\.com$/],
			'Instagram': [/^instagram\.com$/, /^instagr\.am$/, /^m\.instagram\.com$/],
			'Twitter': [/^twitter\.com$/, /^mobile\.twitter\.com$/, /^t\.co$/],
			'Linkedin': [/^linkedin\.com$/, /^m\.linkedin\.com$/, /^www\.linkedin\.com$/],
			'Youtube': [/^youtube\.com$/, /^youtu\.be$/, /^m\.youtube\.com$/, /^www\.youtube\.com$/],
			'Vimeo': [/^vimeo\.com$/, /^player\.vimeo\.com$/],
			'Reddit': [/^reddit\.com$/, /^old\.reddit\.com$/, /^www\.reddit\.com$/],
			'Blogger': [/^blogger\.com$/, /^blogspot\.com$/],
			'Pinterest': [/^pinterest\.com$/, /^www\.pinterest\.com$/],
			'Spotify': [/^spotify\.com$/, /^open\.spotify\.com$/, /^www\.spotify\.com$/],
			'Soundcloud': [/^soundcloud\.com$/, /^m\.soundcloud\.com$/],
			'Github': [/^github\.com$/, /^gist\.github\.com$/],
			'Behance': [/^behance\.net$/, /^www\.behance\.net$/],
			'Flickr': [/^flickr\.com$/, /^www\.flickr\.com$/],
			'Dribbble': [/^dribbble\.com$/, /^www\.dribbble\.com$/],
			'Tumblr': [/^tumblr\.com$/, /^www\.tumblr\.com$/],
			'Foursquare': [/^foursquare\.com$/, /^www\.foursquare\.com$/],
			'Tiktok': [/^tiktok\.com$/, /^www\.tiktok\.com$/],
			'Fiverr': [/^fiverr\.com$/, /^www\.fiverr\.com$/],
			'Upwork': [/^upwork\.com$/, /^www\.upwork\.com$/],
			'Freelancer': [/^freelancer\.com$/, /^www\.freelancer\.com$/]
		};

		let selectedNetwork: string = '';

		try {
			// Extract the domain from the URL
			const url = new URL(val1);
			const domain = url.hostname;

			_.forEach(patterns, (val2, i2) => {
				for (const regex of val2) {
					if (regex.test(domain)) {
						selectedNetwork = i2;
						return false;
					}
				}
			});
		} catch (e) {
			// Handle invalid URL (fallback to 'Website' if URL parsing fails)
			selectedNetwork = 'Website';
		}

		return selectedNetwork !== '' ? selectedNetwork : 'Website';
	};

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="EDITPROFILE">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>

			<Loader active={isLoading} fixed={true} />

			<Popup
				title={text.removeProfilePopup?.title}
				text={text.removeProfilePopup?.text1}
				submitBtn={text.removeProfilePopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showProfileRemovePopup}
				onSubmit={() => onRemoveProfile()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={text.refillProfileFormPopup?.title}
				text={text.refillProfileFormPopup?.text1}
				submitBtn={text.refillProfileFormPopup?.button1?.text}
				closeBtn={text.refillProfileFormPopup?.button2?.text}
				active={popups.showRefillFormPopup}
				onSubmit={() => onFormRefill()}
				onClose={() => {
					localStorage.removeItem('profileRefill');
					setPopups(initPopups);
				}}
			/>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #346343452135']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
				
			<PageHeader heading={text.pageTitle} />
			
			<form onSubmit={(e: any) => onSubmitForm(e)}>
				{
					!_.isEmpty(errors) && 
					text.generalFieldsErrorText &&
					<div className="SECTION has-top-border">
						<div className="grid">
							<div className="row">
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3" dangerouslySetInnerHTML={{ __html: text.generalFieldsErrorText }}></div>
							</div>
						</div>
					</div>
				}

				<div className="SECTION has-top-border">
					<div className="grid">
						<div className="row">
							{
								text.heading1?.text &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="FIELD">
										<div className="FIELD__heading">
											{ text.heading1?.text }
										</div>
									</div>
								</div>
							}

							<div className="col-6 col-sm-4 push-sm-2 col-md-3 push-md-3">
								<div className="FIELD">
									<Toggler
										value={isExtendedForm}
										checkedText={text.toggler4?.on}
										uncheckedText={text.toggler4?.off}
										onChange={(val: boolean) => setIsExtendedForm((id ? true : val))}
									/>
								</div>
							</div>

							{
								isExtendedForm &&
								<div className="col-6 col-sm-4 col-md-3">
									<div className="FIELD">
										<Toggler
											value={profile.isMain}
											checkedText={text.toggler1?.on}
											uncheckedText={text.toggler1?.off}
											onChange={(val: boolean) => {
												setProfile(prevState => ({...prevState, 'isMain': val}))}
											}
										/>
									</div>
								</div>
							}

							{
								isExtendedForm &&
								<div className="col-6 col-sm-4 push-sm-2 col-md-3 push-md-3">
									<div className="FIELD">
										<Toggler
											value={profile.isContactable}
											checkedText={text.toggler2?.on}
											uncheckedText={text.toggler2?.off}
											onChange={(val: boolean) => {
												setProfile(prevState => ({...prevState, 'isContactable': val}))}
											}
										/>
									</div>
								</div>
							}

							{
								isExtendedForm &&
								<div className="col-6 col-sm-4 col-md-3">
									<div className="FIELD">
										<Toggler
											value={profile.isVisible}
											checkedText={text.toggler3?.on}
											uncheckedText={text.toggler3?.off}
											onChange={(val: boolean) => {
												setProfile(prevState => ({...prevState, 'isVisible': val}))}
											}
										/>
									</div>
								</div>
							}
						</div>
					</div>
				</div>

				<div className="SECTION">
					<div className="grid">
						<div className="row">
							{
								(text.heading2?.text || text.heading2?.help) &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									{
										text.heading2?.text &&
										<div className="FIELD">
											<div className="FIELD__heading">
												{ text.heading2?.text }
											</div>
										</div>
									}

									{
										text.heading2?.help &&
										<div className="FIELD__explanation" dangerouslySetInnerHTML={{ __html: text.heading2?.help }}></div>
									}
								</div>
							}

							{
								isExtendedForm &&
								<div className={isExtendedForm ? 'col-12 col-sm-4 push-sm-2 col-md-3 push-md-3' : 'col-12 col-sm-8 push-sm-2 col-md-6 push-md-3'}>
									<div className="FIELD diff1 EDITPROFILE__cover">
										<Upload
											value={profile.covers}
											formats="image/jpg,image/jpeg,image/png"
											maxSize={generalText.keys?.maxUploadFileSize}
											maxFiles={1}
											multiple={false}
											label={text.upload1?.label}
											labelHelp={text.upload1?.help}
											icon={text.upload1?.icon}
											placeholder={text.upload1?.placeholder}
											errors={errors.covers}
											onChange={(acceptedFiles: Array<any>, rejectedFiles: Array<any>) => {
												if (!_.isEmpty(rejectedFiles)) {														
													setErrors(prevState => ({...prevState, 'covers': true}))
													setNotifications(JSON.parse(JSON.stringify(text.upload1?.badFormatError).replace('%repStr1%', (generalText.keys?.maxUploadFileSize / (1024 * 1024)).toString())));
													return;
												}

												setProfile(prevState => ({...prevState, 'covers': acceptedFiles}))
											}}
											setNotifications={(val: any) => setNotifications(val)}
										/>
									</div>
								</div>
							}
							
							<div className={isExtendedForm ? 'col-12 col-sm-4 col-md-3' : 'col-12 col-sm-8 push-sm-2 col-md-6 push-md-3'}>
								<div className="FIELD diff1">
									<Upload
										value={profile.images}
										formats="image/jpg,image/jpeg,image/png,image/heic,image/heif"
										maxSize={generalText.keys?.maxUploadFileSize}
										maxFiles={1}
										multiple={false}
										label={text.upload2?.label}
										labelHelp={text.upload2?.help}
										icon={text.upload2?.icon}
										placeholder={text.upload2?.placeholder}
										errors={errors.images}
										onChange={(acceptedFiles: Array<any>, rejectedFiles: Array<any>) => {
											if (!_.isEmpty(rejectedFiles)) {
												setErrors(prevState => ({...prevState, 'images': true}));
												setNotifications(JSON.parse(JSON.stringify(text.upload2?.badFormatError).replace('%repStr1%', (generalText.keys?.maxUploadFileSize / (1024 * 1024)).toString())));
												return;
											}

											setProfile(prevState => ({...prevState, 'images': acceptedFiles}));
										}}
										setNotifications={(val: any) => setNotifications(val)}
									/>
								</div>
							</div>

							<div className="col-6 col-sm-4 push-sm-2 col-md-3 push-md-3">
								<div className="FIELD diff1">
									<Input
										value={profile.name}
										label={text.input2?.label}
										labelHelp={text.input2?.help}
										placeholder={text.input2?.placeholder}
										type="text"
										errors={errors.name}
										onChange={(val: string) => {
											let slug = val.replace(/^[^a-zA-Z0-9]|[^\w.-]+|[^a-zA-Z0-9]$/g, '-').toLowerCase();

											setProfile(prevState => ({...prevState, 'name': val, 'slug': slug}));
										}}
										setNotifications={(val: any) => setNotifications(val)}
									/>
								</div>
							</div>

							<div className="col-6 col-sm-4 col-md-3">
								<div className="FIELD diff1">
									<Input
										value={profile.slug}
										label={text.input3?.label}
										labelHelp={text.input3?.help}
										placeholder={text.input3?.placeholder}
										icon={text.input3?.icon}
										type="text"
										errors={errors.slug}
										onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'slug': val.replace(/^[^a-zA-Z0-9]|[^\w.-]+|[^a-zA-Z0-9]$/g, '-').toLowerCase()}))}
										}
										setNotifications={(val: any) => setNotifications(val)}
									/>
								</div>
							</div>

							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="FIELD diff1">
									<Select
										value={profile.country}
										label={text.select1?.label}
										labelHelp={text.select1?.help}
										placeholder={text.select1?.placeholder}
										icon={generalText.filter?.popup1?.input1?.icon}
										nullable={true}
										strict={true}
										options={[]}
										dbOptions={'countries'}
										errors={errors.country}
										onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'country': val}))}
										}
										setNotifications={(val: any) => setNotifications(val)}
									/>
								</div>
							</div>

							{
								isExtendedForm &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="FIELD diff1">
										<Select
											value={profile.city}
											label={text.select2?.label}
											labelHelp={text.select2?.help}
											placeholder={text.select2?.placeholder}
											icon={generalText.filter?.popup1?.input2?.icon}
											nullable={true}
											strict={false}
											options={[]}
											dbOptions={'cities'}
											errors={errors.city}
											onChange={(val: string) => {
												setProfile(prevState => ({...prevState, 'city': val}))}
											}
											setNotifications={(val: any) => setNotifications(val)}
										/>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
				
				<div className="SECTION">
					<div className="grid">
						<div className="row">
							{
								(text.heading3?.text || text.heading3?.help) &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									{
										text.heading3?.text &&
										<div className="FIELD">
											<div className="FIELD__heading">
												{ text.heading3?.text }
											</div>
										</div>
									}

									{
										isExtendedForm &&
										text.heading3?.help &&
										<div className="FIELD__explanation" dangerouslySetInnerHTML={{ __html: text.heading3?.help }}></div>
									}
								</div>
							}

							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="FIELD diff1">
									<Select
										value={profile.types[0]}
										label={text.select3?.label}
										labelHelp={text.select3?.help}
										placeholder={text.select3?.placeholder}
										icon={generalText.filter?.popup1?.input3?.icon}
										nullable={true}
										strict={true}
										options={[]}
										dbOptions={'types'}
										errors={errors.types}
										onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'types': [val]}))}
										}
										setNotifications={(val: any) => setNotifications(val)}
									/>

									{/*<Select
										value={profile.types}
										label={text.select3?.label}
										labelHelp={text.select3?.help}
										placeholder={text.select3?.placeholder}
										icon={generalText.filter?.popup1?.input3?.icon}
										nullable={false}
										tag={true}
										strict={true}
										options={[]}
										dbOptions={'types'}
										errors={errors.types}
										onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'types': val}))}
										}
										setNotifications={(val: any) => setNotifications(val)}
									/>*/}
								</div>
							</div>

							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="FIELD diff1">
									<Select
										value={profile.category}
										label={text.select4?.label}
										labelHelp={text.select4?.help}
										placeholder={text.select4?.placeholder}
										icon={generalText.filter?.popup1?.input4?.icon}
										nullable={true}
										strict={true}
										options={[]}
										dbOptions={'categories'}
										errors={errors.category}
										onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'category': val}))}
										}
										setNotifications={(val: any) => setNotifications(val)}
									/>
								</div>
							</div>

							{
								isExtendedForm &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="FIELD diff1">
										<Select
											value={profile.skills}
											label={text.select6?.label}
											labelHelp={text.select6?.help}
											placeholder={text.select6?.placeholder}
											icon={text.select6?.icon}
											nullable={false}
											tag={true}
											strict={false}
											options={[]}
											dbOptions={'skills'}
											errors={errors.skills}
											onChange={(val: string) => {
												setProfile(prevState => ({...prevState, 'skills': val}))}
											}
											setNotifications={(val: any) => setNotifications(val)}
										/>
									</div>
								</div>
							}

							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="FIELD diff1">
									<Textarea
										value={profile.about}
										label={text.textarea1?.label}
										labelHelp={text.textarea1?.help}
										placeholder={text.textarea1?.placeholder}
										minCharLength={50}
										maxCharLength={500}
										errors={errors.about}
										onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'about': val}))}
										}
										setNotifications={(val: any) => setNotifications(val)}
									/>
								</div>
							</div>

							{
								isExtendedForm &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="FIELD diff1">
										<Select
											value={profile.interests}
											label={text.select7?.label}
											labelHelp={text.select7?.help}
											placeholder={text.select7?.placeholder}
											icon={generalText.filter?.popup1?.input5?.icon}
											nullable={false}
											tag={true}
											strict={false}
											options={[]}
											dbOptions={'interests'}
											errors={errors.interests}
											onChange={(val: string) => {
												setProfile(prevState => ({...prevState, 'interests': val}))}
											}
											setNotifications={(val: any) => setNotifications(val)}
										/>
									</div>
								</div>
							}
						</div>
					</div>
				</div>

				{/*
					isExtendedForm &&
					<div className="SECTION">
						<div className="grid">
							<div className="row">
								{
									(text.heading5?.text || text.heading5?.help) &&
									<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
										{
											text.heading5?.text &&
											<div className="FIELD">
												<div className="FIELD__heading">
													{ text.heading5?.text }
												</div>
											</div>
										}

										{
											isExtendedForm &&
											text.heading5?.help &&
											<div className="FIELD__explanation" dangerouslySetInnerHTML={{ __html: text.heading5?.help }}></div>
										}
									</div>
								}

								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="FIELD diff1">
										<Textarea
											value={profile.offer}
											label={text.textarea3?.label}
											labelHelp={text.textarea3?.help}
											placeholder={text.textarea3?.placeholder}
											errors={errors.offer}
											onChange={(val: string) => {
											setProfile(prevState => ({...prevState, 'offer': val}))}
											}
											setNotifications={(val: any) => setNotifications(val)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				*/}

				<div className="SECTION">
					<div className="grid">
						<div className="row">
							{
								(text.heading6?.text || text.heading6?.help) &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									{
										text.heading6?.text &&
										<div className="FIELD">
											<div className="FIELD__heading">
												{ text.heading6?.text }
											</div>
										</div>
									}

									{
										isExtendedForm &&
										text.heading6?.help &&
										<div className="FIELD__explanation" dangerouslySetInnerHTML={{ __html: text.heading6?.help }}></div>
									}
								</div>
							}

							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								{
									_.map(profile.networks, (val1, i1) => {
										return (
											<div key={i1} className={'row' + (errors.networks ? ' error' : '')}>
												{/*<div className="col-12 col-md-5">
													<div className="FIELD diff1">
														<Select
															value={val1.network}
															label={text.loopedInputs1?.select1?.label}
															labelHelp={text.loopedInputs1?.select1?.help}
															placeholder={text.loopedInputs1?.select1?.placeholder}
															icon={text.loopedInputs1?.select1?.icon}
															nullable={true}
															strict={true}
															options={[]}
															dbOptions={'networks'}
															errors={errors['network-' + val1.network.toLowerCase()]}
															onChange={(val: {[key: string]: any}) => {
																// let networks = _.cloneDeep(profile.networks);
																// networks[i1].network = val;
																// setProfile(prevState => ({...prevState, 'networks': networks}));
															}}
															setNotifications={(val: any) => setNotifications(val)}
														/>
													</div>
												</div>*/}

												<div className="col-12">
													<div className="FIELD diff1">
														<Input
															value={val1.url}
															label={text.loopedInputs1?.select2?.label + ': ' + profile.networks[i1].network}
															labelHelp={text.loopedInputs1?.select2?.help}
															placeholder={text.loopedInputs1?.select2?.placeholder}
															icon={text.loopedInputs1?.select2?.icon}
															type="text"
															errors={errors['url-' + val1.network.toLowerCase()]}
															onChange={(val: {[key: string]: any}) => {
																let networks = _.cloneDeep(profile.networks);

																let recognizedNetwork: string = recognizeNetwork(val, parseInt(i1));

																networks[i1].network = recognizedNetwork;
																networks[i1].url = val;

																setProfile(prevState => ({...prevState, 'networks': networks}));
															}}
															setNotifications={(val: any) => setNotifications(val)}
														/>
													</div>
												</div>

												<div className="col-12">
													<div className="EDITPROFILE__addremove">
														<div className="row">
															{
																parseInt(i1) < (auth.availableNetworks - 1) && text.loopedInputs1?.button1?.text &&
																<div className="col-6 col-md-4">
																	<span className="EDITPROFILE__addremove-item BUTTON diff2 size1" onClick={() => {
																		if (parseInt(profile.networks.length) >= auth.availableNetworks) {
																			setNotifications({'text': ['You have reached the limit of available networks. Please contact our support team if you require to extend this list.']});
																			return;
																		}

																		let networks = _.cloneDeep(profile.networks);
																		networks.splice(i1 + 1, 0, initProfile.networks[0]);
																		setProfile(prevState => ({...prevState, 'networks': networks}));

																	}}>{ text.loopedInputs1?.button1?.text }</span>
																</div>
															}

															{
																parseInt(i1) !== 0 && text.loopedInputs1?.button2?.text &&
																<div className="col-6 col-md-4">
																	<span className="EDITPROFILE__addremove-item BUTTON diff2 size1" onClick={() => {
																		let networks = _.cloneDeep(profile.networks);
																		networks.splice(i1, 1);
																		setProfile(prevState => ({...prevState, 'networks': networks}));

																	}}>{ text.loopedInputs1?.button2?.text }</span>
																</div>
															}
														</div>
													</div>
												</div>

												{
													errors.networks &&
													<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
														<p className="error">
															{ errors.networks }
														</p>
													</div>
												}
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>

				{
					!_.isEmpty(errors) && 
					text.generalFieldsErrorText &&
					<div className="SECTION has-top-border">
						<div className="grid">
							<div className="row">
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3" dangerouslySetInnerHTML={{ __html: text.generalFieldsErrorText }}></div>
							</div>
						</div>
					</div>
				}

				<div className="SECTION has-top-border">
					<div className="grid">
						<div className="row">
							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="row">
									{
										text.button1?.text &&
										<div className={id ? 'col-12' : 'col-6'}>
											<button type="submit" className="BUTTON">{ text.button1?.text }</button>
										</div>
									}

									{
										id && text.button2?.text &&
										<div className="col-6">
											<span onClick={() => setPopups({...initPopups, 'showProfileRemovePopup': true})} className="BUTTON diff1">{ text.button2?.text }</span>
										</div>
									}

									{
										text.button3?.text &&
										<div className="col-6">
											<Link to={slugByPageId('user-profiles', lang, routes)} className="BUTTON diff1">{ text.button3?.text }</Link>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default EditProfile;