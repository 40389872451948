import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Controller } from 'react-scrollmagic';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import parse from 'html-react-parser';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { deleteCookie } from './../../other/helpers/cookies';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
import Guide from './../../components/guide/guide';
// import PageHeader from './../../components/page-header/page-header';
import ProfileItem from './../../components/profile-item/profile-item';

// Import styles
import './user-profiles.scss';

interface props {
	'pageId': string
}

const UserProfiles = (props: props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initPopups: {[key: string]: any} = {
		'profileToRaiseId': '',
		'profileToSetMainId': '',
		'showNewProfilePopup': false,
		'showRefillFormPopup': false
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [text, setText] = useState<{[key: string]: any}>({});

	const [profiles, setProfiles] = useState<Array<any>>([]);
	const [profilesPage, setProfilesPage] = useState<number>(1);
	const [profilesTotal, setProfilesTotal] = useState<number>(0);

	const [qualityProfiles, setQualityProfiles] = useState<Array<any>>([]);

	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);

			getQualityProfiles(1);
		}).catch((err) => {			
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	useEffect(() => {
		if (localStorage.getItem('profileRefill')) {
			setPopups(prevState => ({...initPopups, 'showRefillFormPopup': true}))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getQualityProfiles = async (page: number) => {
		await new Promise((resolve, reject) => {
			if (page === 1) {
				setQualityProfiles([]); 
			}

			// Get profiles by user's filter settings
			api.get('/get-quality-profiles/' + page).then((res) => {
				setQualityProfiles(res.data.entries);
				resolve(true);
			}).catch((err) => {
				setNotifications(err.response?.data?.messages);
				resolve(false);
			});
		});
	};

	const getProfiles: any = useCallback((page: number) => {
		setIsLoading(true);

		if (page === 1) {
			setProfiles([]);
			setProfilesPage(1);
			setProfilesTotal(0);
		}

		api.get('/get-paginated-user-profiles/' + page).then((res) => {
			// Push new profiles into cloned state
			setIsLoading(false);
			setProfilesPage(page + 1);
			setProfiles(prevState => ([...prevState, ...res.data.entries]));

			// Add total profiles count from DB
			if (page === 1 && res.data?.total) {
				setProfilesTotal(res.data.total);
			}
		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Check if the user email is unconfirmed
		if (auth.profilesCount === 0 && !auth.isEmailConfirmed) {
			setNotifications(generalText.messages?.emailNotConfirmedMessage);
		}

		// Show message if the first profile is pending
		if (
			auth.profilesCount === 1 && 
			auth.mainProfile?.status === 0
		) {
			setNotifications(generalText.messages?.profileInReviewMessage);
		}

		// // Show message if the first profile was confirmed
		// if (
		// 	auth.profilesCount === 1 && 
		// 	auth.mainProfile?.status === 1
		// ) {
		// 	setNotifications(generalText.messages?.profileInReviewMessage);
		// }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getProfiles(1);
	}, [getProfiles]);

	const onBuyNewProfile = async () => {
		if (!auth.isEmailConfirmed) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.emailNotConfirmedError);

			return;
		}

		if (auth.credits < generalText.keys?.newProfilePrice) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.notEnoughCreditsError);

			return;
		}

		setIsLoading(true);

		api.put('/buy-profile').then((res) => {

			deleteCookie('profileRefill');
			setIsLoading(false);
			setPopups(initPopups);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));
			navigate(slugByPageId('create-profile', lang, routes));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onProfileSetMain = async () => {
		setIsLoading(true);

		let profileData: any = {
			'id': popups.profileToSetMainId
		}

		api.put('/set-main-profile', profileData).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);

			onProfileUpdate(profileData.id);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onProfileRaise = async () => {
		if (_.find(profiles, {'_id': popups.profileToRaiseId}).status === 0) {
			setNotifications(generalText.messages?.profileNotConfirmedMessage);

			return false;
		}

		if (auth.credits < generalText.keys?.raiseProfilePrice) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.notEnoughCreditsError);

			return false;
		}

		let raisedAt: any = _.find(profiles, {'_id': popups.profileToRaiseId})?.raisedAt;

		if (!raisedAt || moment().diff(raisedAt, 'hours') < generalText.keys?.hoursToAllowProfileRaise) {
			setNotifications({
				'title': generalText.messages?.raiseProfileTimeError?.title,
				'text': generalText.messages?.raiseProfileTimeError?.text[0].replace('%repStr1%', moment(raisedAt).add(generalText.keys?.hoursToAllowProfileRaise, 'hours').fromNow())
			});
		} 

		setIsLoading(true);

		let profileData: any = {
			'id': popups.profileToRaiseId
		}

		api.put('/raise-profile', profileData).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications({
				'title': generalText.messages?.raisedProfileMessage?.title,
				'text': [generalText.messages?.raisedProfileMessage?.text[0].replace('%repStr1%', moment(res.data.raisedAt).add(generalText.keys?.hoursToAllowProfileRaise, 'hours').fromNow())]
			});

			onProfileUpdate(profileData.id);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onProfileUpdate = (profileId: string) => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(profiles);
		let index: number = _.findIndex(stateClone, {'_id': profileId});

		api.get('/get-user-profile/' + profileId).then((res) => {			
			stateClone[index] = res.data;

			setIsLoading(false);
			setProfiles(stateClone);

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="USERPROFILES">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Guide
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				nextButton={text.guidePopup?.button1}
			/>

			<Popup
				title={text.refillProfileFormPopup?.title}
				text={text.refillProfileFormPopup?.text1}
				submitBtn={text.refillProfileFormPopup?.button1?.text}
				closeBtn={text.refillProfileFormPopup?.button2?.text}
				active={popups.showRefillFormPopup}
				onSubmit={() => navigate(slugByPageId('create-profile', lang, routes))}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={text.addNewProfilePopup?.title}
				submitBtn={text.addNewProfilePopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.showNewProfilePopup}
				onSubmit={() => onBuyNewProfile()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.addNewProfilePopup?.text1 &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html: text.addNewProfilePopup?.text1 }}></div>
							}

							{
								text.addNewProfilePopup?.text2 &&
								<div className="col-12">
									{
										parse(text.addNewProfilePopup?.text2, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newProfilePrice }</span>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.setMainProfilePopup?.title}
				text={
					popups.profileToSetMainId !== auth.mainProfile?.id ? 
					text.setMainProfilePopup?.text1 :
					text.setMainProfilePopup?.text2
				}
				submitBtn={
					popups.profileToSetMainId !== auth.mainProfile?.id ? 
					text.setMainProfilePopup?.button1?.text : ''
				}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.profileToSetMainId !== ''}
				onSubmit={() => onProfileSetMain()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={text.raiseProfilePopup?.title}
				submitBtn={text.raiseProfilePopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.profileToRaiseId !== ''}
				onSubmit={() => onProfileRaise()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.raiseProfilePopup?.text1 &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html: text.raiseProfilePopup?.text1 }}></div>
							}

							{
								text.raiseProfilePopup?.text2 &&
								<div className="col-12">
									{
										parse(text.raiseProfilePopup?.text2, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.raiseProfilePrice }</span>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #1341352242423']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				{/*<PageHeader heading={text.pageTitle} />*/}

				<div className="SECTION">
					<div className="grid">
						<div className="row">
							{
								_.map(profiles, (val1, i1) => {
									return(
										<div key={i1} className="col-12 col-sm-6 col-md-4">
											<div className="SECTION__item">
												<ProfileItem
													isVisible={val1.isVisible}
													isContactable={val1.isContactable}
													userId={val1.userId}
													profileId={val1._id}
													name={val1.name}
													slug={val1.slug}
													images={val1.images}
													covers={val1.covers}
													country={val1.country}
													city={val1.city}
													types={val1.types}
													category={val1.category}
													offer={val1.offer}
													raisedAt={val1.raisedAt}
													valueRating={val1.valueRating}
													isUnclaimed={val1.isUnclaimed}
													activeAt={val1.activeAt}
													status={val1.status}
													statusMessage={val1.statusMessage}
													profileToRaiseId={(val: string) => setPopups({...initPopups, 'profileToRaiseId': val})}
													profileToSetMainId={(val: string) => setPopups({...initPopups, 'profileToSetMainId': val})}
													onViewStatusMessage={(val: any) => setNotifications({'title': generalText.profileItem?.status2Text, 'text': [val]})}
												/>
											</div>
										</div>
									)
								})
							}

							<div className="col-12 col-sm-6 col-md-4">
								<div className="OTHERITEM full-height">
									<div className="OTHERITEM__content">
										<div className="OTHERITEM__body">
											{
												(profilesTotal < auth.availableProfiles) && 
												text.userProfiles?.createProfile?.button1?.icon &&
												<Link to={slugByPageId(text.userProfiles?.createProfile?.button1?.link, lang, routes)} className="OTHERITEM__link LINK"></Link>
											}
											
											{
												(profilesTotal >= auth.availableProfiles) && 
												text.userProfiles?.buyProfile?.button1?.icon &&
												<div onClick={() => setPopups(prevState => ({...initPopups, 'showNewProfilePopup': true}))} className="OTHERITEM__link LINK"></div>
											}

											<div className="OTHERITEM__icon">
												{
													(profilesTotal < auth.availableProfiles) && 
													text.userProfiles?.createProfile?.button1?.icon &&
													<div className={text.userProfiles?.createProfile?.button1?.icon}></div>
												}

												{
													(profilesTotal >= auth.availableProfiles) && 
													text.userProfiles?.buyProfile?.button1?.icon &&
													<div className={text.userProfiles?.createProfile?.button1?.icon}></div>
												}
											</div>

											{
												(profilesTotal < auth.availableProfiles) && 
												text.userProfiles?.createProfile?.title &&
												<div className="OTHERITEM__text">
													<div className="SECTION__title font-md">
														{ text.userProfiles?.createProfile?.title }
													</div>
												</div>
											}

											{
												(profilesTotal >= auth.availableProfiles) && 
												text.userProfiles?.buyProfile?.title &&
												<div className="OTHERITEM__text">
													<div className="SECTION__title font-md">
														{ text.userProfiles?.buyProfile?.title }
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>

							{
								profiles?.length > 0 && 
								(profilesTotal > profiles.length) && 
								text.userProfiles?.footer?.button1?.text &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="SECTION__item">
										<div className="row">
											<div className="col-6 push-3">
												<button onClick={() => getProfiles(profilesPage)} className="BUTTON">{ text.userProfiles?.footer?.button1?.text }</button>		
											</div>
										</div>
									</div>
								</div>
							}
							
							{
								(profilesTotal <= profiles.length) && 
								text.userProfiles?.footer?.button1?.disabledText &&
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="SECTION__item">
										<div className="row">
											<div className="col-6 push-3">
												<button className="BUTTON diff1" disabled={true}>{ text.userProfiles?.footer?.button1?.disabledText }</button>
											</div>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>

				{
					qualityProfiles?.length > 0 &&
					<div className="SECTION has-top-border USERPROFILES__slider overflow-hidden">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									{
										text.qualityProfiles?.heading &&
										<div className="SLIDER__arrowssmall">
											<div className="SECTION__title font-lg">{ text.qualityProfiles?.heading }</div>

											<div className="SLIDER__arrowssmall-arrows">
												<div className="SLIDER__arrowssmall-item prev">
													<span className={generalText.other?.sliderArrowsSmall}></span>
												</div>

												<div className="SLIDER__arrowssmall-item next">
													<span className={generalText.other?.sliderArrowsSmall}></span>
												</div>
											</div>
										</div>
									}

									<Swiper
										speed={300}
										className="quality-profiles-slider"
										modules={[Navigation]}
										slidesPerView={4.5}
										centeredSlides={false}
										loop={false}
										spaceBetween={10}
										autoplay={false}
										navigation={{
											'prevEl': '.USERPROFILES__slider .SLIDER__arrowssmall-item.prev',
											'nextEl': '.USERPROFILES__slider .SLIDER__arrowssmall-item.next',
										}}
										breakpoints={{
											768: {
												'slidesPerView': 8,
												'spaceBetween': 20
											}
										}}
									>
										{
											_.map(qualityProfiles, (val1, i1) => {
												return (
													<SwiperSlide key={i1}>
														<div className="SECTION__item">
															<ProfileItem
																layout="xs"
																isVisible={true}
																userId={val1.userId}
																profileId={val1._id}
																slug={val1.slug}
																images={val1.images}
																raisedAt={val1.raisedAt}
																activeAt={val1.activeAt}
																setNotifications={(val: {[key: string]: any}) => setNotifications(val)}
															/>
														</div>
													</SwiperSlide>
												)
											})
										}
									</Swiper>
								</div>
							</div>
						</div>
					</div>
				}
			</Controller>
		</div>
	);
}

export default UserProfiles;