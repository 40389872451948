import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';
import { setGeneral } from './../../other/actions/general';

// Import helpers
import { checkCookie } from './../../other/helpers/cookies';
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import Popup from './../../components/popup/popup';
import Loader from './../../components/loader/loader';
import Textarea from './../../components/textarea/textarea';

// Import styles
import './post-compose-item.scss';

interface props {
	'text': any,
	'showNewPostPopup': boolean,
	'onGetPosts': any,
	'onShowNewPostPopup': any
};

const PostComposeItem = (props: props) => {
	const dispatch = useDispatch();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const filter = useSelector((state: {[key: string]: any}) => state.filter);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const general = useSelector((state: {[key: string]: any}) => state.general);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initPost: {[key: string]: any} = {
		'post': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [post, setPost] = useState<{[key: string]: any}>(initPost);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);
	const [errors, setErrors] = useState<{[key: string]: any}>({});

	useEffect(() => {
		if (!_.isEmpty(errors)) {
			setErrors({});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [post]);

	useEffect(() => {
		if (!props.showNewPostPopup) { return; }

		onBeforeShowPopup();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.showNewPostPopup]);

	const onBeforeShowPopup = () => {
		if (!auth.isAuthenticated) {
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		if (!auth.mainProfile?.slug) {
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.profileIsNotSelected);
			return;
		}

		if (auth.mainProfile?.status !== 1) {
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.profileNotConfirmedMessage);
			return;
		}
	}

	const onBeforeAddPost = () => {
		if (
			filter.postKind?.toLowerCase() === 'listings' &&
			auth.credits < generalText.keys?.newListingsPostPrice
		){
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.notEnoughCreditsError);
			return;
		}

		if (
			filter.postKind?.toLowerCase() === 'feedback' &&
			auth.credits < generalText.keys?.newFeedbackPostPrice
		){
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.notEnoughCreditsError);
			return;
		}
		
		if (
			filter.postKind?.toLowerCase() === 'questions' &&
			auth.credits < generalText.keys?.newQuestionsPostPrice
		){
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.notEnoughCreditsError);
			return;
		}
		
		if (
			filter.postKind?.toLowerCase() === 'promotional' &&
			auth.credits < generalText.keys?.newPromotionalPostPrice
		){
			props.onShowNewPostPopup(false);
			setNotifications(generalText.messages?.notEnoughCreditsError);
			return;
		}
	}

	const onAddPost = (e: any) => {
		e.preventDefault();

		onBeforeAddPost();

		setIsLoading(true);

		let stateClone = _.cloneDeep(post);
		let fieldErrors = validateFields(stateClone, ['post']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		let formData: any = {
			'post': stateClone.post
		}

		formData['postKind'] = filter.postKind || 'Listings';
		formData['country'] = filter.country || auth.mainProfile?.country;
		formData['type'] = filter.type || auth.mainProfile?.types?.[0];
		formData['category'] = filter.category || auth.mainProfile?.category;

		if (filter.city) {
			formData['city'] = filter.city;
		}

		if (filter.interest) {
			formData['interest'] = filter.interest;
		}

		api.post('/add-post', formData).then((res1) => {

			setPost(initPost);
			props.onShowNewPostPopup(false);
			
			setNotifications({
				'title': generalText.messages?.messageSubmittedMessage?.title,
				'text': [
					...generalText.messages?.messageSubmittedMessage?.text,
					'<p><a href="' + auth.mainProfile?.slug + '" class="LINK">View post</a></p>'
				]
			});

			setIsLoading(false);
			props.onGetPosts();
			
			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		
		});
	}

	let profileImage: string = '';

	if (auth.mainProfile?.images?.[0]?.path) {
		profileImage = apiBasePath + '/uploads/' + (checkCookie('siteWebpSupported') ? auth.mainProfile?.images[0].path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : auth.mainProfile?.images[0].path);
	}

	return (
		<>
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={props.text?.title}
				closeBtn={generalText?.generalPopup?.button1?.text}
				submitBtn={props.text?.button1?.text}
				active={props.showNewPostPopup}
				onClose={() => props.onShowNewPostPopup(false)}
				onSubmit={(e: any) => onAddPost(e)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								auth.mainProfile?.name && 
								props.text?.text1 &&
								<div className="col-12">
									<div className="POSTCOMPOSEITEM__filter">
										{
											parse(props.text?.text1, {
												replace: (domNode: any) => {
													if (domNode.attribs?.class === '%repStr1%') {
														return <Link to={slugByPageId('user-profiles', lang, routes)} className="TEXTBUBBLE no-top-margin clickable">{ auth.mainProfile?.name }</Link>;
													}
													if (domNode.attribs?.class === '%repStr2%') {
														return <span className={'TEXTBUBBLE no-top-margin clickable' + (filter.postKind ? ' has-bg bg-green' : '')} onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ filter.postKind || 'Listings' }</span>;
													}
													if (domNode.attribs?.class === '%repStr3%' && (filter.type || auth.mainProfile?.types?.[0])) {
														return <span className={'TEXTBUBBLE no-top-margin clickable' + (filter.type ? ' has-bg bg-green' : '')} onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ filter.type || auth.mainProfile?.types?.[0] }</span>;
													}
													if (domNode.attribs?.class === '%repStr4%' && (filter.category || auth.mainProfile?.category)) {
														return <span className={'TEXTBUBBLE no-top-margin clickable' + (filter.category ? ' has-bg bg-green' : '')} onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ filter.category || auth.mainProfile?.category }</span>;
													}
													if (domNode.attribs?.class === '%repStr5%' && filter.city) {
														return <span className={'TEXTBUBBLE no-top-margin clickable' + (filter.city ? ' has-bg bg-green' : '')} onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ filter.city }</span>;
													}
													if (domNode.attribs?.class === '%repStr6%' && (filter.country || auth.mainProfile?.country)) {
														return <span className={'TEXTBUBBLE no-top-margin clickable' + (filter.country ? ' has-bg bg-green' : '')} onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ filter.country || auth.mainProfile?.country }</span>;
													}
													if (domNode.attribs?.class === '%repStr7%' && filter.interest) {
														return <> to <span className={'TEXTBUBBLE no-top-margin clickable' + (filter.interest ? ' has-bg bg-green' : '')} onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ filter.interest }</span></>;
													}
													if (domNode.attribs?.class === '%repStr8%') {
														return <span className="FILTERITEM__settings-edit LINK no-top-margin clickable" onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))}>{ domNode.children?.[0]?.data }</span>;
													}
												}
											})
										}
									</div>
								</div>
							}

							{
								!auth.mainProfile?.name && props.text?.text3 &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html: props.text?.text3 }}></div>
							}

							<div className="col-12">
								<div className="POSTCOMPOSEITEM__input">
									<div className="PROFILEIMAGE diff1">
										{
											auth.isAuthenticated &&
											<Link to={slugByPageId('user-profiles', lang, routes)} className="LINK"></Link>
										}
										
										<span className="PROFILEIMAGE__inner">
											<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
											<span className="image" style={{ backgroundImage: 'url(' + profileImage + ')' }}></span>
										</span>
									</div>

									<div className="POSTCOMPOSEITEM__input-message">
										<div className={'FIELD' + (errors.post ? ' error' : '')}>
											<Textarea
												value={post.post}
												label={props.text?.textarea1?.label}
												placeholder={
													((!filter.postKind || filter.postKind?.toLowerCase() === 'listings') && props.text?.textarea1?.listingsPlaceholder) ||
													(filter.postKind?.toLowerCase() === 'feedback' && props.text?.textarea1?.feedbackPlaceholder) ||
													(filter.postKind?.toLowerCase() === 'questions' && props.text?.textarea1?.questionsPlaceholder) ||
													(filter.postKind?.toLowerCase() === 'educational' && props.text?.textarea1?.educationalPlaceholder) ||
													(filter.postKind?.toLowerCase() === 'opportunities' && props.text?.textarea1?.opportunitiesPlaceholder) ||
													(filter.postKind?.toLowerCase() === 'promotional' && props.text?.textarea1?.promotionalPlaceholder)
												}
												onChange={(val: string) => setPost(prevState => ({...prevState, 'post': val}))}
												minCharLength={2}
												maxCharLength={10000}
											/>

											{
												errors.post &&
												<p className="error">
													{ errors.post }
												</p>
											}
										</div>
									</div>
								</div>
							</div>

							{
								props.text?.prices?.listingsPostPrice && 
								filter.postKind?.toLowerCase() === 'listings' &&
								<div className="col-12">
									{
										parse(props.text?.prices?.listingsPostPrice, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newListingsPostPrice }</span>;
												}
											}
										})
									}
								</div>
							}

							{
								props.text?.prices?.feedbackPostPrice && 
								filter.postKind?.toLowerCase() === 'feedback' &&
								<div className="col-12">
									{
										parse(props.text?.prices?.feedbackPostPrice, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newFeedbackPostPrice }</span>;
												}
											}
										})
									}
								</div>
							}

							{
								props.text?.prices?.questionsPostPrice && 
								filter.postKind?.toLowerCase() === 'questions' &&
								<div className="col-12">
									{
										parse(props.text?.prices?.questionsPostPrice, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newQuestionsPostPrice }</span>;
												}
											}
										})
									}
								</div>
							}

							{
								props.text?.prices?.opportunitiesPostEarning && 
								filter.postKind?.toLowerCase() === 'opportunities' &&
								<div className="col-12">
									{
										parse(props.text?.prices?.opportunitiesPostEarning, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newOpportunitiesPostEarning }</span>;
												}
											}
										})
									}
								</div>
							}

							{
								props.text?.prices?.educationalPostEarning && 
								filter.postKind?.toLowerCase() === 'educational' &&
								<div className="col-12">
									{
										parse(props.text?.prices?.educationalPostEarning, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newEducationalPostEarning }</span>;
												}
											}
										})
									}
								</div>
							}

							{
								props.text?.prices?.promotionalPostPrice && 
								filter.postKind?.toLowerCase() === 'promotional' &&
								<div className="col-12">
									{
										parse(props.text?.prices?.promotionalPostPrice, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <span>{ generalText.keys?.newPromotionalPostPrice }</span>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || generalText.generalPopup?.title}
				text={notifications?.text || ['Unhandled error - #1253464534534']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
		</>
	);
}

export default PostComposeItem;
