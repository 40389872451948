// import _ from 'lodash';

export const validateFields = (fields: {[key: string]: any}, types: Array<any>) => {
	const capitalizeFirstLetter = (fieldValue: string) => {
		return fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
	}

	const capitalizeFirstLetters = (fieldValue: string) => {
		let splitStr = fieldValue.toLowerCase().split(' ');
		
		for (let i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
		}

		return splitStr.join(' ');
	}

	const truncateNewLines = (fieldValue: string) => {
		return fieldValue.replace(/\r?\n|\r/g, ' ');
	}

	const truncateExtraNewLines = (fieldValue: string) => {
		return fieldValue.replace(/[\r\n]{3,}/g, '\n\n');
	}

	const truncateLeadingAndEndingSpaces = (fieldValue: string) => {
		return fieldValue.replace(/^[ \t]+|[ \t]+$/g, '');
	}

	const truncateDoubleSpaces = (fieldValue: string) => {
		return fieldValue.replace(/[ ]{2,}/g, ' ');
	}

	const truncateSpaces = (fieldValue: string) => {
		return fieldValue.replace(/\s/g, '');
	}

	const validateEmail = (fieldName: string, fieldValue: string, field: string) => {
		const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!regex.test(String(fieldValue).toLowerCase())) {
			errors[fieldName] = 'You have entered an invalid ' + field.toLowerCase() + ' address. Please use a valid ' + field.toLowerCase() + ' address.';
		}
	}

	const validatePhone = (fieldName: string, fieldValue: string, field: string) => {
		const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

		if (!regex.test(String(fieldValue).toLowerCase())) {
			errors[fieldName] = 'You have entered an invalid ' + field.toLowerCase() + ' number. Please enter a valid ' + field.toLowerCase() + ' number.';
		}
	}

	const isEmpty = (fieldName: string, fieldValue: any, field: string) => {
		if (!fieldValue || fieldValue?.length === 0) {
			errors[fieldName] = field + ' field was left empty.';
		}
	}

	// const isAccepted = (fieldName: string, fieldValue: any, field: string) => {
	// 	if (!fieldValue) {
	// 		errors[fieldName] = 'Please read and accept with our ' + field + ' in order to continue.';
	// 	}
	// }

	const minCharLength = (fieldName: string, fieldValue: any, minChars: number, field: string) => {
		if (fieldValue?.length < minChars) {
			errors[fieldName] = field + ' cannot have less than ' + minChars + ' characters.';
		}
	}

	const maxCharLength = (fieldName: string, fieldValue: any, maxChars: number, field: string) => {
		if (fieldValue?.length > maxChars) {
			errors[fieldName] = field + ' cannot have more than ' + maxChars + ' characters.';
		}
	}

	const minLength = (fieldName: string, fieldValue: any, minLength: number, field: string) => {
		if (fieldValue?.length < minLength) {
			errors[fieldName] = 'Please add more than ' + minLength + ' ' + field + '.';
		}
	}

	const maxLength = (fieldName: string, fieldValue: any, maxLength: number, field: string) => {
		if (fieldValue?.length > maxLength) {
			errors[fieldName] = 'Please add less than ' + maxLength + ' ' + field + '.';
		}
	}

	const isNumeric = (fieldName: string, fieldValue: any, field: string) => {
		if (!Number.isInteger(fieldValue)) {
			errors[fieldName] = field + ' has to have a numeric value.';
		}
	}

	const isJSONFormat = (fieldName: string, fieldValue: any, field: string) => {
		try {
			JSON.parse(fieldValue);
		} catch (e) {
			errors[fieldName] = field + ' is not a valid JSON format.';
		}
	}

	const isHTMLFormat = (fieldName: string, fieldValue: any, field: string) => {
		const doc = document.createElement('div');
		doc.innerHTML = fieldValue;

		if (doc.innerHTML.trim() !== fieldValue.trim()) {
			errors[fieldName] = field + ' is not a valid HTML format.';
		}
	}

	const declineSymbolsExceptLatinExtended = (fieldName: string, fieldValue: any, field: string) => {
		const regex = /(?![a-zA-Z])[!-~]+/;

		if (regex.test(fieldValue)) {
			errors[fieldName] = field + ' cannot contain any symbols, please remove them.';
		}
	}

	// const declineSymbols = (fieldName: string, fieldValue: any, field: string) => {
	// 	const regex = /[^a-zA-Z ]/;

	// 	if (regex.test(fieldValue)) {
	// 		errors[fieldName] = field + ' cannot contain any symbols, please remove them.';
	// 	}
	// }

	const declineAllExceptDotsAndDashes = (fieldName: string, fieldValue: any, field: string) => {
		const regex = /^[^a-zA-Z0-9]|[^\w.-]+|[^a-zA-Z0-9]$/g;

		if (regex.test(fieldValue)) {
			errors[fieldName] = field + ' can only contain letters, numbers, hyphens, underscores and periods. Also please remove any symbols from the front and/or back if there is any.';
		}
	}

	const declineSpaces = (fieldName: string, fieldValue: any, field: string) => {
		const regex = /\s/;

		if (regex.test(fieldValue)) {
			errors[fieldName] = field + ' cannot contain any empty spaces, please remove them.';
		}
	}

	const declineUrl = (fieldName: string, fieldValue: any) => {
		let regex = /(https?:\/\/)|(www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

		if (regex.test(fieldValue)) {
			errors[fieldName] = '"' + fieldValue + '" contains a link to external website. Please remove this URL from the text.';
		}
	}

	const validateUrl = (fieldName: string, fieldValue: any) => {
		let regex = /(https?:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/;

		if (!regex.test(fieldValue)) {
			errors[fieldName] = 'Please enter a valid website URL.';
		}
	}

	// const validateSocialNetwork = (fieldName: string, network: any, url: any) => {
	// 	let availableNetworks: Array<any> = [
	// 		{ 'key': 'website', 'domain': '', 'fullDomain': 'https://', 'name': 'Website' },
	// 		{ 'key': 'facebook', 'domain': 'facebook.com', 'fullDomain': 'https://www.facebook.com', 'name': 'Facebook' },
	// 		{ 'key': 'instagram', 'domain': 'instagram.com', 'fullDomain': 'https://www.instagram.com', 'name': 'Instagram' },
	// 		{ 'key': 'twitter', 'domain': 'twitter.com', 'fullDomain': 'https://twitter.com', 'name': 'Twitter' },
	// 		{ 'key': 'linkedin', 'domain': 'linkedin.com', 'fullDomain': 'https://www.linkedin.com', 'name': 'LinkedIn' },
	// 		{ 'key': 'youtube', 'domain': 'youtube.com', 'fullDomain': 'https://www.youtube.com', 'name': 'YouTube' },
	// 		{ 'key': 'vimeo', 'domain': 'vimeo.com', 'fullDomain': 'https://vimeo.com', 'name': 'Vimeo' },
	// 		{ 'key': 'reddit', 'domain': 'reddit.com', 'fullDomain': 'https://www.reddit.com', 'name': 'Reddit' },
	// 		{ 'key': 'blogger', 'domain': 'blogger.com', 'fullDomain': 'https://www.blogger.com', 'name': 'Blogger' },
	// 		{ 'key': 'pinterest', 'domain': 'pinterest.com', 'fullDomain': 'https://www.pinterest.com', 'name': 'Pinterest' },
	// 		{ 'key': 'spotify', 'domain': 'spotify.com', 'fullDomain': 'https://open.spotify.com', 'name': 'Spotify' },
	// 		{ 'key': 'soundcloud', 'domain': 'soundcloud.com', 'fullDomain': 'https://soundcloud.com', 'name': 'SoundCloud' },
	// 		{ 'key': 'github', 'domain': 'github.com', 'fullDomain': 'https://github.com', 'name': 'GitHub' },
	// 		{ 'key': 'behance', 'domain': 'behance.net', 'fullDomain': 'https://www.behance.net', 'name': 'Behance' },
	// 		{ 'key': 'flickr', 'domain': 'flickr.com', 'fullDomain': 'https://www.flickr.com', 'name': 'Flickr' },
	// 		{ 'key': 'dribbble', 'domain': 'dribbble.com', 'fullDomain': 'https://dribbble.com', 'name': 'Dribbble' },
	// 		{ 'key': 'tumblr', 'domain': 'tumblr.com', 'fullDomain': 'https://www.tumblr.com', 'name': 'Tumblr' },
	// 		{ 'key': 'foursquare', 'domain': 'foursquare.com', 'fullDomain': 'https://foursquare.com', 'name': 'Foursquare' },
	// 		{ 'key': 'tiktok', 'domain': 'tiktok.com', 'fullDomain': 'https://www.tiktok.com', 'name': 'TikTok' },
	// 		{ 'key': 'fiverr', 'domain': 'fiverr.com', 'fullDomain': 'https://www.fiverr.com', 'name': 'Fiverr' },
	// 		{ 'key': 'upwork', 'domain': 'upwork.com', 'fullDomain': 'https://www.upwork.com', 'name': 'Upwork' },
	// 		{ 'key': 'freelancer', 'domain': 'freelancer.com', 'fullDomain': 'https://www.freelancer.com', 'name': 'Freelancer' },
	// 	];

	// 	// Check if network exists
	// 	if (_.find(availableNetworks, {'key': network})) {
	// 		let networkObj: { [key: string]: any } = _.find(availableNetworks, {'key' : network});

	// 		// Regex if string contains key between http slashes and .com
	// 		let regex = new RegExp('((http(s)?(://))+(www.)?([a-z0-9]+[.])?(' + networkObj.domain + '/))[^sbn|]*[^.,;:?!@^$ -]');

	// 		if (networkObj.domain && !regex.test(url)) {
	// 			errors[fieldName] = 'Your ' + networkObj.name + ' profile URL does not match with this example - ' + networkObj.fullDomain + '/yourExternalUsername.';
	// 		}
	// 	}
	// }

	let errors: {[key: string]: any} = {};

	if (types.includes('general')) {
		// if (fields.hasOwnProperty('isTermsAndPrivacyAccepted')) {
		// 	let fieldValue = fields.isTermsAndPrivacyAccepted;

		// 	isAccepted('isTermsAndPrivacyAccepted', fieldValue, 'Terms & Conditions and Privacy Policy');
		// }

		// if (fields.hasOwnProperty('isInformationValidityAccepted')) {
		// 	let fieldValue = fields.isInformationValidityAccepted;

		// 	isAccepted('isInformationValidityAccepted', fieldValue, 'information validity');
		// }
	}

	if (types.includes('user')) {
		if (fields.hasOwnProperty('emailForPassword')) {
			let fieldValue = fields.emailForPassword;

			validateEmail('emailForPassword', fieldValue, 'Email');
			maxCharLength('emailForPassword', fieldValue, 200, 'Email');
			minCharLength('emailForPassword', fieldValue, 2, 'Email');
			isEmpty('emailForPassword', fieldValue, 'Email');

			fields.emailForPassword = fieldValue.toLowerCase();
		}

		if (fields.hasOwnProperty('email')) {
			let fieldValue = fields.email;

			validateEmail('email', fieldValue, 'Email');
			maxCharLength('email', fieldValue, 200, 'Email');
			minCharLength('email', fieldValue, 2, 'Email');
			isEmpty('email', fieldValue, 'Email');

			fields.email = fieldValue.toLowerCase();
		}

		if (fields.hasOwnProperty('password')) {
			let fieldValue = fields.password;

			minCharLength('password', fieldValue, 6, 'Password');
			isEmpty('password', fieldValue, 'Password');

			fields.password = fieldValue;
		}

		if (fields.hasOwnProperty('newPassword')) {
			let fieldValue = fields.newPassword;

			minCharLength('newPassword', fieldValue, 6, 'Old password');
			isEmpty('newPassword', fieldValue, 'Old password');

			fields.newPassword = fieldValue;
		}
		
		if (fields.hasOwnProperty('oldPassword')) {
			let fieldValue = fields.oldPassword;

			minCharLength('oldPassword', fieldValue, 6, 'New password');
			isEmpty('oldPassword', fieldValue, 'New password');

			fields.oldPassword = fieldValue;
		}

		if (fields.hasOwnProperty('firstName')) {
			let fieldValue = fields.firstName;

			// declineSpaces('firstName', fieldValue, 'First name');
			// declineSymbols('firstName', fieldValue, 'First name');
			declineSymbolsExceptLatinExtended('firstName', fieldValue, 'First name');
			maxCharLength('firstName', fieldValue, 100, 'First name');
			minCharLength('firstName', fieldValue, 2, 'First name');
			isEmpty('firstName', fieldValue, 'First name');

			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = capitalizeFirstLetters(fieldValue.toLowerCase());

			fields.firstName = fieldValue;
		}

		if (fields.hasOwnProperty('lastName')) {
			let fieldValue = fields.lastName;

			// declineSpaces('lastName', fieldValue, 'Last name');
			// declineSymbols('lastName', fieldValue, 'Last name');
			declineSymbolsExceptLatinExtended('lastName', fieldValue, 'Last name');
			maxCharLength('lastName', fieldValue, 100, 'Last name');
			minCharLength('lastName', fieldValue, 2, 'Last name');
			isEmpty('lastName', fieldValue, 'Last name');

			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = capitalizeFirstLetters(fieldValue.toLowerCase());

			fields.lastName = fieldValue;
		}

		if (fields.hasOwnProperty('invitationCodeUsed')) {
			let fieldValue = fields.invitationCodeUsed;

			maxCharLength('invitationCodeUsed', fieldValue, 100, 'Invitation code');
			minCharLength('invitationCodeUsed', fieldValue, 2, 'Invitation code');
			isEmpty('invitationCodeUsed', fieldValue, 'Invitation code');

			fields.invitationCodeUsed = fieldValue;
		}

		if (fields.hasOwnProperty('confirmationId')) {
			let fieldValue = fields.confirmationId;

			maxCharLength('confirmationId', fieldValue, 12, 'Confirmation code');
			minCharLength('confirmationId', fieldValue, 6, 'Confirmation code');
			isEmpty('confirmationId', fieldValue, 'Confirmation code');

			fields.confirmationId = fieldValue;
		}
	}

	if (types.includes('profile')) {
		if (fields.hasOwnProperty('images')) {
			let fieldValue = fields.images;

			isEmpty('images', fieldValue, 'Profile image');

			fields.images = fieldValue;
		}

		if (fields.hasOwnProperty('name')) {
			let fieldValue = fields.name;

			// declineSymbols('name', fieldValue, 'Profile name');
			// declineSymbolsExceptLatinExtended('name', fieldValue, 'Profile name');
			maxCharLength('name', fieldValue, 100, 'Profile name');
			minCharLength('name', fieldValue, 2, 'Profile name');
			isEmpty('name', fieldValue, 'Profile name');

			// fieldValue = capitalizeFirstLetters(fieldValue.toLowerCase());

			fields.name = fieldValue;
		}

		if (fields.hasOwnProperty('slug')) {
			let fieldValue = fields.slug;

			declineAllExceptDotsAndDashes('slug', fieldValue, 'Profile slug');
			maxCharLength('slug', fieldValue, 20, 'Profile slug');
			minCharLength('slug', fieldValue, 2, 'Profile slug');
			isEmpty('slug', fieldValue, 'Profile slug');		

			fields.slug = fieldValue.toLowerCase();
		}

		if (fields.hasOwnProperty('types')) {
			let fieldValue = fields.types;

			// maxLength('types', fieldValue, 3, 'types');
			minLength('types', fieldValue, 1, 'types');
			isEmpty('types', fieldValue, 'Types');

			fields.types = fieldValue;
		}

		if (fields.hasOwnProperty('skills')) {
			let fieldValue = fields.skills;

			maxLength('skills', fieldValue, 20, 'skills');
			minLength('skills', fieldValue, 2, 'skills');
			isEmpty('skills', fieldValue, 'skills');
		}

		if (fields.hasOwnProperty('about')) {
			let fieldValue = fields.about;

			maxCharLength('about', fieldValue, 500, 'About text');
			declineUrl('about', fieldValue);
			minCharLength('about', fieldValue, 50, 'About text');
			isEmpty('about', fieldValue, 'About text');
			
			// fieldValue = truncateNewLines(fieldValue);
			// fieldValue = capitalizeFirstLetter(fieldValue);
			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = truncateDoubleSpaces(fieldValue);
			fieldValue = truncateExtraNewLines(fieldValue);

			fields.about = fieldValue;
		}

		if (fields.hasOwnProperty('contactEmail') && fields.contactEmail !== '') {
			let fieldValue = fields.contactEmail;

			validateEmail('contactEmail', fieldValue, 'Email');
			maxCharLength('contactEmail', fieldValue, 200, 'Email');
			minCharLength('contactEmail', fieldValue, 2, 'Email');
			isEmpty('contactEmail', fieldValue, 'Email');

			fields.contactEmail = fieldValue.toLowerCase();
		}

		if (fields.hasOwnProperty('contactPhone') && fields.contactPhone !== '') {
			let fieldValue = fields.contactPhone;

			validatePhone('contactPhone', fieldValue, 'Phone');
			maxCharLength('contactPhone', fieldValue, 15, 'Phone');
			minCharLength('contactPhone', fieldValue, 2, 'Phone');
			isEmpty('contactPhone', fieldValue, 'Phone');

			fieldValue = truncateSpaces(fieldValue);
			fields.contactPhone = fieldValue;
		}

		if (fields.hasOwnProperty('additionalContactInfo') && fields.additionalContactInfo !== '') {
			let fieldValue = fields.additionalContactInfo;

			declineUrl('additionalContactInfo', fieldValue);
			maxCharLength('additionalContactInfo', fieldValue, 10000, 'Additional contact information');
			minCharLength('additionalContactInfo', fieldValue, 2, 'Additional contact information');
			isEmpty('additionalContactInfo', fieldValue, 'Additional contact information');
			
			fieldValue = truncateNewLines(fieldValue);
			fieldValue = capitalizeFirstLetter(fieldValue);

			fields.additionalContactInfo = fieldValue;
		}

		if (fields.hasOwnProperty('offer') && fields.offer !== '') {
			let fieldValue = fields.offer;

			maxCharLength('offer', fieldValue, 10000, 'Offer instructions');
			minCharLength('offer', fieldValue, 2, 'Offer instructions');
			isEmpty('offer', fieldValue, 'Offer instructions');
			
			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = truncateDoubleSpaces(fieldValue);
			fieldValue = truncateExtraNewLines(fieldValue);

			fields.offer = fieldValue;
		}

		if (fields.hasOwnProperty('networks')) {
			let fieldValue = fields.networks;

			minLength('networks', fieldValue, 1, 'Social network');

			fieldValue.forEach((val1: any, i1: any) => {
				// validateSocialNetwork('url-' + val1.network.toLowerCase(), val1.network.toLowerCase(), val1.url);
				validateUrl('url-' + val1.network.toLowerCase(), val1.url);
				declineSpaces('url-' + val1.network.toLowerCase(), val1.url, 'Network URL');
				isEmpty('network-' + val1.network.toLowerCase(), val1.network, 'Network');
			});
		}
	}

	if (types.includes('filter')) {
		if (fields.hasOwnProperty('country')) {
			let fieldValue = fields.country;

			maxCharLength('country', fieldValue, 100, 'Country');
			minCharLength('country', fieldValue, 2, 'Country');
			isEmpty('country', fieldValue, 'Country');

			fieldValue = capitalizeFirstLetters(fieldValue);

			fields.country = fieldValue;
		}

		if (fields.hasOwnProperty('city')) {
			let fieldValue = fields.city;

			maxCharLength('city', fieldValue, 100, 'City');
			minCharLength('city', fieldValue, 2, 'City');
			isEmpty('city', fieldValue, 'City');

			fieldValue = capitalizeFirstLetters(fieldValue);

			fields.city = fieldValue;
		}

		if (fields.hasOwnProperty('interests')) {
			let fieldValue = fields.interests;

			maxLength('interests', fieldValue, 10, 'interests');
			minLength('interests', fieldValue, 1, 'interests');
			isEmpty('interests', fieldValue, 'Interests');

			fields.interests = fieldValue;
		}

		if (fields.hasOwnProperty('interest')) {
			let fieldValue = fields.interest;

			maxCharLength('interest', fieldValue, 20, 'Interest');
			minCharLength('interest', fieldValue, 1, 'Interest');
			isEmpty('interest', fieldValue, 'Interest');

			fields.interest = fieldValue;
		}

		if (fields.hasOwnProperty('type')) {
			let fieldValue = fields.type;

			maxCharLength('type', fieldValue, 100, 'Type');
			minCharLength('type', fieldValue, 2, 'Type');
			isEmpty('type', fieldValue, 'Type');

			fieldValue = capitalizeFirstLetters(fieldValue);

			fields.type = fieldValue;
		}

		if (fields.hasOwnProperty('category')) {
			let fieldValue = fields.category;

			maxCharLength('category', fieldValue, 100, 'Category');
			minCharLength('category', fieldValue, 2, 'Category');
			isEmpty('category', fieldValue, 'Category');

			fieldValue = capitalizeFirstLetters(fieldValue);

			fields.category = fieldValue;
		}

		if (fields.hasOwnProperty('searchPhrase')) {
			let fieldValue = fields.searchPhrase;

			// declineSymbolsExceptLatinExtended('searchPhrase', fieldValue, 'Search phrase');
			maxCharLength('searchPhrase', fieldValue, 100, 'Search phrase');
			minCharLength('searchPhrase', fieldValue, 2, 'Search phrase');
			isEmpty('searchPhrase', fieldValue, 'Search phrase');

			// fieldValue = capitalizeFirstLetters(fieldValue.toLowerCase());

			fields.searchPhrase = fieldValue;
		}

		if (fields.hasOwnProperty('postKind')) {
			let fieldValue = fields.postKind;

			maxLength('postKind', fieldValue, 20, 'Post kind');
		}
	}

	if (types.includes('comment')) {
		if (fields.hasOwnProperty('comment')) {
			let fieldValue = fields.comment;

			declineUrl('comment', fieldValue);
			maxCharLength('comment', fieldValue, 10000, 'Comment text');
			minCharLength('comment', fieldValue, 2, 'Comment text');
			isEmpty('comment', fieldValue, 'Comment text');
			
			// fieldValue = truncateNewLines(fieldValue);
			// fieldValue = capitalizeFirstLetter(fieldValue);
			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = truncateDoubleSpaces(fieldValue);
			fieldValue = truncateExtraNewLines(fieldValue);
			
			fields.comment = fieldValue;
		}
	}

	if (types.includes('post')) {
		if (fields.hasOwnProperty('post')) {
			let fieldValue = fields.post;

			// declineUrl('post', fieldValue);
			maxCharLength('post', fieldValue, 10000, 'Post text');
			minCharLength('post', fieldValue, 2, 'Post text');
			isEmpty('post', fieldValue, 'Post text');
			
			// fieldValue = truncateNewLines(fieldValue);
			// fieldValue = capitalizeFirstLetter(fieldValue);
			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = truncateDoubleSpaces(fieldValue);
			fieldValue = truncateExtraNewLines(fieldValue);
			
			fields.post = fieldValue;
		}
	}

	if (types.includes('report')) {
		if (fields.hasOwnProperty('report')) {
			let fieldValue = fields.report;

			declineUrl('report', fieldValue);
			maxCharLength('report', fieldValue, 10000, 'Report text');
			minCharLength('report', fieldValue, 2, 'Report text');
			isEmpty('report', fieldValue, 'Report text');
			
			fieldValue = capitalizeFirstLetter(fieldValue);
			
			fields.report = fieldValue;
		}
	}

	if (types.includes('conversation')) {
		if (fields.hasOwnProperty('message')) {
			let fieldValue = fields.message;

			declineUrl('message', fieldValue);
			maxCharLength('message', fieldValue, 10000, 'Conversation message text');
			minCharLength('message', fieldValue, 2, 'Conversation message text');
			isEmpty('message', fieldValue, 'Conversation message text');
			
			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = truncateDoubleSpaces(fieldValue);
			fieldValue = truncateExtraNewLines(fieldValue);
			
			fields.message = fieldValue;
		}
	}

	if (types.includes('checkout')) {
		if (fields.hasOwnProperty('creditsToBuy')) {
			let fieldValue = fields.creditsToBuy;

			isNumeric('creditsToBuy', fieldValue, 'Amount of credits');
			maxCharLength('creditsToBuy', fieldValue, 5, 'Name');
			minCharLength('creditsToBuy', fieldValue, 2, 'Name');
			isEmpty('creditsToBuy', fieldValue, 'Name');

			fields.creditsToBuy = fieldValue;
		}

		if (fields.hasOwnProperty('name')) {
			let fieldValue = fields.name;

			minCharLength('name', fieldValue, 2, 'Name');
			isEmpty('name', fieldValue, 'Name');

			fields.name = fieldValue;
		}

		if (fields.hasOwnProperty('line1')) {
			let fieldValue = fields.line1;

			minCharLength('line1', fieldValue, 2, 'Address line');
			isEmpty('line1', fieldValue, 'Address line');

			fields.line1 = fieldValue;
		}

		if (fields.hasOwnProperty('city')) {
			let fieldValue = fields.city;

			minCharLength('city', fieldValue, 2, 'City');
			isEmpty('city', fieldValue, 'City');

			fields.city = fieldValue;
		}

		if (fields.hasOwnProperty('postalCode')) {
			let fieldValue = fields.postalCode;

			minCharLength('postalCode', fieldValue, 2, 'Postal Code');
			isEmpty('postalCode', fieldValue, 'Postal Code');

			fields.postalCode = fieldValue;
		}

		if (fields.hasOwnProperty('state')) {
			let fieldValue = fields.state;

			minCharLength('state', fieldValue, 2, 'State');
			isEmpty('state', fieldValue, 'State');

			fields.state = fieldValue;
		}

		if (fields.hasOwnProperty('country')) {
			let fieldValue = fields.country;

			minCharLength('country', fieldValue, 2, 'Country');
			isEmpty('country', fieldValue, 'Country');

			fields.country = fieldValue;
		}
	}

	if (types.includes('email')) {
		if (fields.hasOwnProperty('name')) {
			let fieldValue = fields.name;

			maxCharLength('name', fieldValue, 300, 'Name');
			minCharLength('name', fieldValue, 2, 'Name');
			isEmpty('name', fieldValue, 'Name');

			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = capitalizeFirstLetters(fieldValue.toLowerCase());

			fields.name = fieldValue;
		}

		if (fields.hasOwnProperty('aggregationQuery')) {
			let fieldValue = fields.aggregationQuery;

			isJSONFormat('aggregationQuery', fieldValue, 'Aggregation query');
			minCharLength('aggregationQuery', fieldValue, 2, 'Aggregation query');
			isEmpty('aggregationQuery', fieldValue, 'Aggregation query');

			fields.aggregationQuery = fieldValue;
		}

		if (fields.hasOwnProperty('title')) {
			let fieldValue = fields.title;

			maxCharLength('title', fieldValue, 300, 'Title');
			minCharLength('title', fieldValue, 2, 'Title');
			isEmpty('title', fieldValue, 'Title');

			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = capitalizeFirstLetters(fieldValue.toLowerCase());

			fields.title = fieldValue;
		}

		if (fields.hasOwnProperty('body')) {
			let fieldValue = fields.body;

			isHTMLFormat('body', fieldValue, 'Body');
			minCharLength('body', fieldValue, 2, 'Body');
			isEmpty('body', fieldValue, 'Body');

			fields.body = fieldValue;
		}
	}

	return errors;
}